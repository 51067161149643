import useWebSocket from 'react-use-websocket'

const BASEURI = `${process.env.NODE_ENV === 'development' ? 'ws://localhost:8000' : 'wss://app.helpcare.ai'}/api/table/ws/`

export interface ChangeEventMessageData {
  data: ChangeEventData
  ids: number[]
}

export interface ChangeEventData {
  table: string
  type: string
  record: BaseRecord
  columns: Column[]
  errors: any
  commit_timestamp: string
  schema: string
  old_record: OldRecord
}

export interface BaseRecord {
  id: string
}

export interface Column {
  name: string
  type: string
}

export interface OldRecord {
  id: string
}

type SupabaseEventListner = (data: ChangeEventData) => void

const useWs = (tableName: string, onMessage: SupabaseEventListner) => {
  useWebSocket<ChangeEventMessageData>(`${BASEURI}${tableName}`, {
    onMessage: (e) => {
      const data: ChangeEventMessageData = JSON.parse(e.data)
      return onMessage(data.data)
    },
    shouldReconnect: () => false,
  })
}

export default useWs
