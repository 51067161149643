import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Loader from '../Loader'
import { GridColDef } from '@mui/x-data-grid'
import { useNavigate } from 'react-router'
import { useQuery } from '@tanstack/react-query'
import { FC, useMemo, useState } from 'react'
import CalendarTodayOutlined from '@mui/icons-material/CalendarTodayOutlined'
import AccessTimeOutlined from '@mui/icons-material/AccessTimeOutlined'
import dayjs from 'dayjs'
import useReportsApi from './Reports/api'
import { Protect } from '@clerk/clerk-react'
import Grid from '../common/Grid'
import GridContainer from '../common/GridContainer'

const LIMIT = 100

const cols: GridColDef[] = [
  {
    field: 'title',
    headerName: 'REPORT',
    cellClassName: 'grid-link',
    minWidth: 350,
  },
  {
    field: 'date',
    headerName: 'DATE',
    minWidth: 200,
    cellClassName: 'small-cell',
    renderCell: (e) => (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
        <CalendarTodayOutlined
          sx={{ color: 'text.secondary', fontSize: '14px' }}
        />
        {e.value}
      </Box>
    ),
  },
  {
    field: 'time',
    headerName: 'TIME',
    minWidth: 200,
    cellClassName: 'small-cell',
    renderCell: (e) => (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
        <AccessTimeOutlined
          sx={{ color: 'text.secondary', fontSize: '14px' }}
        />
        {e.value}
      </Box>
    ),
  },
]

const MyData: FC = () => {
  const [page, setPage] = useState(0)
  const { getAllReports } = useReportsApi()
  const navigate = useNavigate()

  const { data, isLoading, isError } = useQuery({
    queryKey: ['my-reports'],
    queryFn: () => getAllReports(),
  })

  const tableRows = useMemo(
    () =>
      (data || []).map((e) => {
        const data = {
          id: e.id,
          title: e.title,
        } as any
        try {
          const date = dayjs(new Date(e.created_at))
          data.date = date.format('ddd, MMM D')
          data.time = date.format('h:mm A')
        } catch (e) {
          data.date = ''
          data.time = ''
        }
        return data
      }),
    [data]
  )

  if (isLoading) {
    return <Loader />
  }

  if (isError) {
    return <Box>Error</Box>
  }

  return (
    <Protect
      permission="org:feature:read_data_view"
      fallback={
        <Typography variant="h2">
          You are not allowed to access this page
        </Typography>
      }
    >
      <GridContainer width="100%">
        <Grid
          hideFooter
          checkboxSelection
          disableRowSelectionOnClick={true}
          slots={{
            noRowsOverlay: () => (
              <Box display={'flex'} justifyContent={'center'} pt={3}>
                <Typography variant="subtitle2" color="text.primary">
                  To see your meting notes be sure to invite
                  notetaker.hanna@helpcare.ai to your meetings.
                </Typography>
              </Box>
            ),
          }}
          columns={cols}
          rows={tableRows}
          rowHeight={40}
          autosizeOnMount={true}
          onCellClick={(e) => {
            if (e.field === 'title') {
              navigate(`${e.id}`)
            }
          }}
          paginationModel={{
            page: page,
            pageSize: LIMIT,
          }}
          onPaginationModelChange={(e) => {
            setPage(e.page)
          }}
        />
      </GridContainer>
    </Protect>
  )
}

export default MyData
