/**
 * ![red 50](https://mui.com/static/colors-preview/red-50-24x24.png) ![red 100](https://mui.com/static/colors-preview/red-100-24x24.png) ![red 200](https://mui.com/static/colors-preview/red-200-24x24.png) ![red 300](https://mui.com/static/colors-preview/red-300-24x24.png) ![red 400](https://mui.com/static/colors-preview/red-400-24x24.png) ![red 500](https://mui.com/static/colors-preview/red-500-24x24.png) ![red 600](https://mui.com/static/colors-preview/red-600-24x24.png) ![red 700](https://mui.com/static/colors-preview/red-700-24x24.png) ![red 800](https://mui.com/static/colors-preview/red-800-24x24.png) ![red 900](https://mui.com/static/colors-preview/red-900-24x24.png) ![red A100](https://mui.com/static/colors-preview/red-A100-24x24.png) ![red A200](https://mui.com/static/colors-preview/red-A200-24x24.png) ![red A400](https://mui.com/static/colors-preview/red-A400-24x24.png) ![red A700](https://mui.com/static/colors-preview/red-A700-24x24.png)
 */
const red = {
  25: '#fffbfa',
  50: '#fef3f2',
  100: '#fee4e2',
  200: '#fecdca',
  300: '#fda29b',
  400: '#f97066',
  500: '#f04438',
  600: '#d92d20',
  700: '#b42318',
  800: '#912018',
  900: '#7a271a',
}

export default red
