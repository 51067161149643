import { SvgIconProps } from '@mui/material'
import { CustomEdge, ICustomNode } from '../../CustomNode/typing'
import Audio from './Tooltip/Audio'
import Video from './Tooltip/Video'
import {
  Call,
  Check,
  CheckCircleOutline,
  FlagRounded,
  Info,
  LocalHospital,
  TextSnippet,
  VideoLibrary,
} from '@mui/icons-material'
import Transcript from './Tooltip/Transcript'

export const initialVisible = true

export const delay = 700

export const commonIconProps: SvgIconProps = {
  color: 'secondary',
}

export const initialEdges: CustomEdge[] = [
  { id: 'e1-2', source: 'n1', target: 'n2' },
  { id: 'e2-3', source: 'n2', target: 'n3' },
  { id: 'e3-4', source: 'n3', target: 'n4' },
  { id: 'e4-5', source: 'n4', target: 'n5', style: { strokeDasharray: '5,5' } },
  { id: 'e5-6', source: 'n5', target: 'n6', style: { strokeDasharray: '5,5' } },
  { id: 'e6-7', source: 'n6', target: 'n7', style: { strokeDasharray: '5,5' } },
  { id: 'e7-8', source: 'n7', target: 'n8', style: { strokeDasharray: '5,5' } },
]

export const initialNodes: ICustomNode[] = [
  {
    id: 'n1',
    type: 'custom',
    position: { x: 100, y: 200 },
    data: {
      label: 'Outreach initiated',
      visible: initialVisible,
      icon: <FlagRounded {...commonIconProps} />,
      caption: ' ',
    },
  },
  {
    id: 'n2',
    type: 'custom',
    position: { x: 300, y: 200 },
    data: {
      label: 'Outbound Call',
      caption: ' ',
      visible: initialVisible,
      icon: <Call {...commonIconProps} />,
      action: {
        top: {
          secondaryIcon: (
            <Info color={'info'} sx={{ fontSize: '14px', cursor: 'pointer' }} />
          ),
          tooltip: <Audio />,
        },
      },
    },
  },
  {
    id: 'n3',
    type: 'custom',
    position: { x: 500, y: 200 },
    data: {
      label: 'Summary',
      visible: initialVisible,
      icon: <TextSnippet {...commonIconProps} />,
      action: {
        bottom: {
          secondaryIcon: (
            <Info color={'info'} sx={{ fontSize: '14px', cursor: 'pointer' }} />
          ),
          tooltip: <Transcript />,
        },
      },
    },
  },
  {
    id: 'n4',
    type: 'custom',
    position: { x: 700, y: 200 },
    data: {
      label: 'Scheduling',
      caption: ' ',
      visible: initialVisible,
      icon: <VideoLibrary {...commonIconProps} />,
      action: {
        top: {
          secondaryIcon: (
            <Info color={'info'} sx={{ fontSize: '14px', cursor: 'pointer' }} />
          ),
          tooltip: <Video />,
        },
      },
    },
  },
  {
    id: 'n5',
    type: 'custom',
    position: { x: 900, y: 200 },
    data: {
      caption: 'Checkpoint',
      label: 'Pre-visit',
      visible: initialVisible,
      icon: <Check {...commonIconProps} color="warning" />,
    },
  },
  {
    id: 'n6',
    type: 'custom',
    position: { x: 1100, y: 200 },
    data: {
      caption: '',
      label: 'Appointment Visit',
      visible: initialVisible,
      icon: <LocalHospital {...commonIconProps} />,
    },
  },
  {
    id: 'n7',
    type: 'custom',
    position: { x: 1300, y: 200 },
    data: {
      caption: 'Checkpoint',
      label: 'Post-visit',
      visible: initialVisible,
      icon: <Check {...commonIconProps} color="warning" />,
    },
  },
  {
    id: 'n8',
    type: 'custom',
    position: { x: 1500, y: 200 },
    data: {
      label: 'Care gap closed',
      caption: ' ',
      visible: initialVisible,
      icon: <CheckCircleOutline {...commonIconProps} color="success" />,
    },
  },
]
