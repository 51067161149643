import { useState, useEffect } from 'react'
import { LinearProgress, Box, Typography } from '@mui/material'
import { green, red } from '@mui/material/colors'

const AnimatedProgress = ({ value = 0 }) => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    // Animate from 0 to target value
    const timer = setTimeout(() => {
      setProgress(value)
    }, 100)

    return () => clearTimeout(timer)
  }, [value])

  // Calculate color based on percentage
  const getColor = (percentage: number) => {
    if (percentage <= 50) {
      // Red range (dark to light)
      // MUI red shades: 900 (darkest) to 100 (lightest)
      const redIndex = Math.floor((percentage / 50) * 8) + 1 // 1 to 9
      const shade = (10 - redIndex) * 100 // Convert to MUI shade (900 to 100)
      //@ts-ignore
      return red[shade]
    } else {
      // Green range (light to dark)
      // MUI green shades: 100 (lightest) to 900 (darkest)
      const greenIndex = Math.floor(((percentage - 50) / 50) * 8) + 1 // 1 to 9
      const shade = greenIndex * 100
      //@ts-ignore
      return green[shade]
    }
  }

  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
        display: 'flex',
        gap: 1,
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Typography variant="body2" color="text.secondary">
          {`(${progress.toFixed(2)}%)`}
        </Typography>
      </Box>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          width: '100%',
          height: 10,
          borderRadius: 5,
          backgroundColor: 'grey.200',
          '& .MuiLinearProgress-bar': {
            backgroundColor: getColor(progress),
            transition: 'transform 1s ease-out, background-color 1s ease-out',
          },
        }}
      />
    </Box>
  )
}

export default AnimatedProgress
