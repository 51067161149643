import { useApi } from '../../providers/ApiProvider'
import { promiseToaster, useToast } from '../../providers/Toast'

const API_BASE = '/api/file/audio/'

const useAudioFileApi = () => {
  const api = useApi()
  const { showToast } = useToast()

  const uploadFile = promiseToaster(
    async ({ audio, duration }: { audio: Blob; duration: number }) => {
      const formData = new FormData()
      formData.append('audio', audio, 'recording.webm')
      const data = await api.post(
        `${API_BASE}upload?duration=${duration}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      return data.data
    },
    'File uploaded, you transcription is being generated',
    '',
    showToast
  )

  return {
    uploadFile,
  }
}

export default useAudioFileApi
