const blue = {
  25: '#EDEBFD',
  50: '#DBD7FC',
  100: '#C9C4FA',
  200: '#B7B0F9',
  300: '#9388F5',
  400: '#6F61F2',
  500: '#4B39EF',
  600: '#3C2EBF',
  700: '#2D228F',
  800: '#1E1760',
  900: '#0F0B30',
}

export default blue
