import Box from '@mui/material/Box'
import { PropsWithChildren, FC } from 'react'

interface GridContainerProps {
  height?: string
  width?: string
}

const GridContainer: FC<PropsWithChildren<GridContainerProps>> = ({
  children,
  height = 'calc(100vh - 140px)',
  width = 'calc(100vw - 240px)',
}) => {
  return (
    <Box
      sx={({ palette, typography }) => ({
        height: height,
        width: width,
        '& .grid-link': {
          color: palette.text.primary,
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          '&:hover': {
            textDecoration: 'underline',
          },
          ...typography.subtitle2,
        },
        '& .small-cell': {
          color: palette.text.secondary,
          display: 'flex',
          alignItems: 'center',
          ...typography.subtitle3,
        },
      })}
    >
      {children}
    </Box>
  )
}
export default GridContainer
