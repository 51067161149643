import useCallLogsApi from './api'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Loader from '../Loader'
import { useNavigate } from 'react-router'
import { useQuery } from '@tanstack/react-query'
import { FC, useMemo } from 'react'
import { millisToTimeString, parseDate } from './util'
import { Protect } from '@clerk/clerk-react'
import { cols } from './column-def'
import Grid from '../common/Grid'
import GridContainer from '../common/GridContainer'

const CallLogs: FC = () => {
  const navigate = useNavigate()
  const { getCalls } = useCallLogsApi()

  const { data, isError, isLoading } = useQuery({
    queryKey: ['table-data', 'call_logs'],
    queryFn: getCalls,
  })

  const rows = useMemo(() => {
    return (data || []).map((e) => {
      const data: any = { ...e }
      data['duration'] = millisToTimeString(e.duration || 0)
      parseDate(e, data, 'start_date_time', 'start')
      parseDate(e, data, 'end_date_time', 'end')
      return data
    })
  }, [data])

  if (isLoading) {
    return <Loader />
  }

  if (isError) {
    return <Box>Error</Box>
  }

  return (
    <Protect
      role="org:global_admin"
      fallback={
        <Typography variant="h2">
          You are not allowed to access this page
        </Typography>
      }
    >
      <GridContainer>
        <Grid
          checkboxSelection
          disableRowSelectionOnClick={true}
          slots={{
            noRowsOverlay: () => (
              <Box display={'flex'} justifyContent={'center'} pt={3}>
                <Typography variant="subtitle2" color="text.primary">
                  To see your meting notes be sure to invite
                  notetaker.hanna@helpcare.ai to your meetings.
                </Typography>
              </Box>
            ),
          }}
          columns={cols}
          rows={rows}
          rowHeight={40}
          pageSizeOptions={[25, 50, 100]}
          onCellClick={(e) => {
            if (e.field === 'title') {
              navigate(`${e.id}`)
            }
          }}
        />
      </GridContainer>
    </Protect>
  )
}

export default CallLogs
