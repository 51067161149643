import {
  OrganizationSwitcher,
  useOrganization,
  useUser,
} from '@clerk/clerk-react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router'

const Home: FC = () => {
  const { user } = useUser()
  const { organization } = useOrganization()
  const navigate = useNavigate()
  useEffect(() => {
    navigate('/my-meetings')
    // eslint-disable-next-line
  }, [])
  return (
    <Box>
      <Typography
        variant="h2"
        sx={{ pb: 2 }}
      >{`Welcome, ${user?.fullName}`}</Typography>
      {organization === null && (
        <>
          <Typography variant="h6" component={'h3'} sx={{ pb: 1 }}>
            Please select an organization to continue.
          </Typography>
          <OrganizationSwitcher hidePersonal={true} />
        </>
      )}
    </Box>
  )
}

export default Home
