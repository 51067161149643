import { Components, Theme } from '@mui/material'

const overrides: Components<Omit<Theme, 'components'>> = {
  MuiButton: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiIconButton: {
    defaultProps: {
      disableRipple: true,
    },
  },
}

export default overrides
