import { FC, useState } from 'react'
import '@blocknote/core/fonts/inter.css'
import { BlockNoteView } from '@blocknote/mantine'
import '@blocknote/mantine/style.css'
import { useCreateBlockNote } from '@blocknote/react'
import { Box, Button, Stack, useTheme } from '@mui/material'
import usePagesApi, { Page } from '../PageTree/api'
import { Edit, Save } from '@mui/icons-material'
import { Protect } from '@clerk/clerk-react'
import { BlockNoteSchema, defaultBlockSpecs } from '@blocknote/core'
import useAutoSave from '../../hooks/useAutoSave'

const schema = BlockNoteSchema.create({
  blockSpecs: {
    //first pass all the blockspecs from the built in, default block schema
    ...defaultBlockSpecs,

    // disable blocks you don't want
    file: undefined as any,
    audio: undefined as any,
    image: undefined as any,
    video: undefined as any,
  },
})

const MeetingPage: FC<{
  data: Page
}> = ({ data }) => {
  const editor = useCreateBlockNote({
    //@ts-ignore
    initialContent: data.blocks || [{ type: 'heading', content: 'Untitled' }],
    schema,
  })
  const { palette } = useTheme()
  const [isEdit, setIsEdit] = useState(false)

  const save = async (isAutoSave: boolean) => {
    console.log('Auto Save', isAutoSave)
    const showToaster = isAutoSave ? false : true
    console.log('Show Toaster', showToaster)
    await savePage(
      {
        ...data,
        blocks: editor.document as any,
      },
      showToaster
    )
    if (!isAutoSave && isEdit) {
      setIsEdit(false)
    }
  }

  const { savePage } = usePagesApi()

  const [setBlocks] = useAutoSave(isEdit, editor, save)

  // Renders the editor instance using a React component.
  return (
    <>
      <Protect permission="org:feature:write_pages">
        <Box
          sx={({ palette }) => ({
            minHeight: '54px',
            height: '54px',
            gap: 2,
            justifyContent: 'space-between',
            borderBottom: `1px solid ${palette.divider}`,
          })}
        >
          {!isEdit ? (
            <Button
              color="primary"
              onClick={() => setIsEdit(true)}
              endIcon={<Edit />}
              size="small"
            >
              Edit
            </Button>
          ) : (
            <>
              <Stack direction="row" spacing={2}>
                <Button
                  color="primary"
                  onClick={() => setIsEdit(false)}
                  size="small"
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  onClick={() => save(false)}
                  endIcon={<Save />}
                  size="small"
                >
                  Save
                </Button>
              </Stack>
            </>
          )}
        </Box>
      </Protect>
      <BlockNoteView
        editor={editor}
        editable={isEdit}
        theme={palette.mode}
        onChange={() => setBlocks(editor.document)}
      />
    </>
  )
}

export default MeetingPage
