import { CallRecord } from './api'
import dayjs from 'dayjs'
import * as colors from '@mui/material/colors'

export const formatUSPhoneNumber = (number: string) => {
  if (!number) {
    return ''
  }
  // Remove all non-numeric characters except the leading '+'
  const cleaned = number.replace(/[^\d+]/g, '')

  // Check if the number has the '+1' country code and 10 digits after it
  const hasCountryCode = cleaned.startsWith('+1')
  const digits = hasCountryCode ? cleaned.slice(2) : cleaned.replace(/\D/g, '')

  // Check if we have exactly 10 digits for the main number
  if (digits.length !== 10) {
    return number // Return input as-is if it's not 10 digits
  }

  // Format the number in (123) 456-7890 pattern
  const formattedNumber = `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6)}`

  return hasCountryCode ? `+1 ${formattedNumber}` : formattedNumber
}

export const getColorForType = (value: string, mode: string) => {
  switch (value) {
    case 'webCall':
    case 'silence-timed-out':
    case 'In Progress':
      return colors.blueGrey[mode === 'dark' ? 700 : 100]
    case 'inboundPhoneCall':
      return colors.blue[mode === 'dark' ? 700 : 100]
    case 'customer-ended-call':
      return colors.purple[mode === 'dark' ? 700 : 100]
    case 'outboundPhoneCall':
    case 'assistant-ended-call':
    case 'Complete':
      return colors.green[mode === 'dark' ? 700 : 100]
    case 'assistant-forwarded-call':
      return colors.pink[mode === 'dark' ? 900 : 100]
  }
  if (value?.includes('error')) {
    return colors.red[mode === 'dark' ? 900 : 100]
  }
}

export const parseDate = (
  e: CallRecord,
  data: any,
  field: keyof CallRecord,
  new_field_prefix: string
) => {
  try {
    if (e[field]) {
      const date = dayjs(new Date(e[field]))
      data[`${new_field_prefix}_date`] = date.format('ddd, MMM D')
      data[`${new_field_prefix}_time`] = date.format('h:mm A')
    } else {
      data[`${new_field_prefix}_date`] = '--'
      data[`${new_field_prefix}_time`] = '--'
    }
  } catch (e) {
    data[`${new_field_prefix}_date`] = '--'
    data[`${new_field_prefix}_time`] = '--'
  }
}

export const millisToTimeString = (millis: number) => {
  const hours = Math.floor(millis / (1000 * 60 * 60))
    .toString()
    .padStart(2, '0')
  const minutes = Math.floor((millis % (1000 * 60 * 60)) / (1000 * 60))
    .toString()
    .padStart(2, '0')
  const seconds = Math.floor((millis % (1000 * 60)) / 1000)
    .toString()
    .padStart(2, '0')

  return `${hours}:${minutes}:${seconds}`
}
