import React, { useEffect, useRef, useState } from 'react'
import { Box, IconButton, Slider, Stack, Typography } from '@mui/material'
import { PlayArrow, Pause, Forward10, Replay10 } from '@mui/icons-material'

interface AudioPlayerProps {
  audioBlob: Blob
}

const formatTime = (seconds: number): string => {
  const mins = Math.floor(seconds / 60)
  const secs = Math.floor(seconds % 60)
  return `${mins}:${secs < 10 ? '0' : ''}${secs}`
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ audioBlob }) => {
  const audioRef = useRef<HTMLAudioElement | null>(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener('timeupdate', () => {
        setCurrentTime(audioRef.current?.currentTime || 0)
      })
      audioRef.current.addEventListener('loadedmetadata', () => {
        setDuration(audioRef.current?.duration || 0)
      })
    }
  }, [audioBlob])

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause()
      } else {
        audioRef.current.play()
      }
      setIsPlaying(!isPlaying)
    }
  }

  const handleTimeChange = (_event: Event, value: number | number[]) => {
    const time = value as number
    setCurrentTime(time)
    if (audioRef.current) {
      audioRef.current.currentTime = time
    }
  }

  const handleForward = () => {
    if (audioRef.current) {
      audioRef.current.currentTime += 10
    }
  }

  const handleRewind = () => {
    if (audioRef.current) {
      audioRef.current.currentTime -= 10
    }
  }

  return (
    <Box
      sx={{
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box component={'audio'} ref={audioRef} src={audioBlob as any} />

      {/* Time slider */}
      <Box
        sx={{ width: '100%', mb: 2, justifyContent: 'center', maxWidth: 500 }}
      >
        <Stack spacing={2} direction="row" alignItems="center">
          <Typography variant="subtitle3" color="text.secondary">
            {formatTime(currentTime)}
          </Typography>
          <Slider
            size="small"
            min={0}
            max={duration}
            value={currentTime}
            color="secondary"
            onChange={handleTimeChange}
            aria-label="Time"
          />
          <Typography variant="subtitle3" color="text.secondary">
            {formatTime(duration)}
          </Typography>
        </Stack>
      </Box>

      {/* Controls */}
      <Stack
        direction="row"
        spacing={1}
        justifyContent="center"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <IconButton onClick={handleRewind} size="small">
          <Replay10 />
        </IconButton>

        <IconButton
          onClick={handlePlayPause}
          sx={{
            bgcolor: 'secondary.main',
            color: 'white',
            '&:hover': {
              bgcolor: 'secondary.dark',
            },
          }}
        >
          {isPlaying ? <Pause /> : <PlayArrow />}
        </IconButton>

        <IconButton onClick={handleForward} size="small">
          <Forward10 />
        </IconButton>
      </Stack>

      {/* Volume control
      <Stack direction="row" spacing={2} alignItems="center">
        <IconButton size="small">
          <VolumeIcon />
        </IconButton>
        <Slider
          size="small"
          min={0}
          max={1}
          step={0.1}
          value={volume}
          onChange={handleVolumeChange}
          aria-label="Volume"
          sx={{ width: 100 }}
        />
      </Stack> */}
    </Box>
  )
}

export default AudioPlayer
