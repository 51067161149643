import { useAuth } from '@clerk/clerk-react'
import NoLogin from './components/NoLogin'
import CoreApp from './components/App'
import { useApi } from './providers/ApiProvider'
import { useEffect } from 'react'

const Root = () => {
  const { isSignedIn } = useAuth()
  const api = useApi()

  useEffect(() => {
    //@ts-ignore
    window.api = api
  }, [api])

  if (isSignedIn) {
    return <CoreApp />
  }
  return <NoLogin />
}

export default Root
