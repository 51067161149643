import Box from '@mui/material/Box'
import { useParams } from 'react-router'
import { useQuery } from '@tanstack/react-query'
import MeetingPage from './MeetingPage'
import Loader from '../Loader'
import ErrorBoundary from '../ErrorBoundary'
import useMeetingsApi from '../Meetings/api'
import { Protect } from '@clerk/clerk-react'

export default function NotionPageRenderer() {
  const params = useParams()

  const { getMeetingById } = useMeetingsApi()

  const { data, isLoading } = useQuery({
    queryKey: ['meeting-data', params.id],
    queryFn: () => getMeetingById(params.id || ''),
  })

  if (isLoading) {
    return (
      <Box sx={{ mt: 8 }}>
        <Loader />
      </Box>
    )
  }
  if (!data) {
    return (
      <Box sx={{ mt: 8 }}>
        <Loader />
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        '& .bn-default-styles': {
          fontFamily: 'Lato,sans-serif',
        },
      }}
      key={params.id}
    >
      <Protect permission="org:feature:read_meeting_notes">
        <Box maxWidth={'lg'}>
          <ErrorBoundary>
            <MeetingPage data={data} />
          </ErrorBoundary>
        </Box>
      </Protect>
    </Box>
  )
}
