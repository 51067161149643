import { FC, useMemo, useState } from 'react'
import '@blocknote/core/fonts/inter.css'
import { BlockNoteView } from '@blocknote/mantine'
import '@blocknote/mantine/style.css'
import { useCreateBlockNote } from '@blocknote/react'
import {
  Autocomplete,
  Box,
  Chip,
  Divider,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { AccessTimeOutlined, CalendarTodayOutlined } from '@mui/icons-material'
import useMeetingsApi from '../Meetings/api'
import { MeetingsResponse } from '../Meetings/typing'
import dayjs from 'dayjs'
import MeetingToolbar from '../Meetings/Toolbar'
import useAutoSave from '../../hooks/useAutoSave'

interface IMeetingsProps {
  data: MeetingsResponse
}

const MeetingPage: FC<IMeetingsProps> = ({ data }) => {
  const [isEdit, setIsEdit] = useState(false)
  const [label, setLabel] = useState('')
  const [keywords, setKeywords] = useState<string[]>([])
  const [attendees, setAttendees] = useState<string[]>([])

  const blocks = useMemo(() => {
    if (data.view !== 'default') {
      return (
        data.alternate_views.find((e) => e.id_name === data.view)?.blocks ||
        data.blocks
      )
    } else {
      return data.blocks
    }
  }, [data])

  const formattedBlocks = useMemo(() => {
    for (const row of blocks) {
      try {
        if (row.type === 'table') {
          ;(row.content as any).rows.forEach((e: any) => {
            if (e.cells[0] === 'Event time') {
              e.cells[1] = new Date(e.cells[1]).toLocaleString()
            }
          })
        }
      } catch (e) {
        console.error('Error formatting date :: ', e)
      }
    }
    return blocks
  }, [blocks])

  const meetingsFormattedData = useMemo(() => {
    setLabel(data?.meta.label)
    setAttendees(data?.attendees || [])
    const keywords = [...data?.properties].find((e) => e.key === 'Keywords')
    setKeywords(keywords?.value.split(',') || [])

    const formattedData = {
      date: '',
      time: '',
    }
    if (!data) return
    const eventTime = [...data?.properties].find((e) => e.key === 'Event time')
    if (eventTime) {
      const date = dayjs(new Date(eventTime.value))
      formattedData.date = date.format('ddd, MMM D')
      formattedData.time = date.format('h:mm A')
    }
    return formattedData
  }, [data])

  const editor = useCreateBlockNote({
    //@ts-ignore
    initialContent: formattedBlocks,
  })

  const { palette } = useTheme()
  const { updateMeetingNotes } = useMeetingsApi()

  const save = async (isAutoSave: boolean) => {
    console.log('Auto Save', isAutoSave)
    const showToaster = isAutoSave ? false : true
    console.log('Show Toaster', showToaster)
    data.blocks = editor.document as any
    data.meta.label = label
    data.attendees = attendees
    data.properties.forEach((e) => {
      e.value = e.key === 'Attendee Emails' ? attendees.join(', ') : e.value
      e.value = e.key === 'Keywords' ? keywords.join(', ') : e.value
    })
    await updateMeetingNotes(data.id || '', data, showToaster)
    if (!isAutoSave && isEdit) {
      setIsEdit(false)
    }
  }

  const [setBlocks] = useAutoSave(isEdit, editor, save)

  // Renders the editor instance using a React component.
  return (
    <>
      <MeetingToolbar
        editState={[isEdit, setIsEdit]}
        meetingId={data.id}
        save={() => save(false)}
      />

      {/* Header container */}
      <Box sx={{ px: 7, mb: 5 }}>
        {isEdit ? (
          <TextField
            id="meetting-label"
            value={label}
            autoComplete="off"
            onChange={(e) => {
              setLabel(e.target.value)
            }}
            slotProps={{
              input: {
                style: { fontSize: 64, fontWeight: 'bold' },
              },
            }}
            variant="standard"
            fullWidth
          />
        ) : (
          <Typography variant="h1" fontWeight="bold" gutterBottom>
            {label}
          </Typography>
        )}

        <Stack direction="row" spacing={2} sx={{ mt: 2, alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <CalendarTodayOutlined
              sx={{ color: 'text.secondary', fontSize: '14px' }}
            />
            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
              {meetingsFormattedData?.date}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <AccessTimeOutlined
              sx={{ color: 'text.secondary', fontSize: '14px' }}
            />
            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
              {meetingsFormattedData?.time}
            </Typography>
          </Box>
        </Stack>
        <Stack direction="row" spacing={2} sx={{ mt: 2, alignItems: 'center' }}>
          <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
            Keywords:
          </Typography>
          {isEdit ? (
            <Autocomplete
              id="free-solo-keywords"
              multiple
              freeSolo
              fullWidth
              value={keywords}
              onChange={(e, values) => {
                setKeywords(values)
              }}
              open={false}
              options={[]}
              renderInput={(params) => <TextField {...params} />}
            />
          ) : (
            <Stack
              direction="row"
              spacing={2}
              sx={{ flexWrap: 'wrap' }}
              useFlexGap
            >
              {[...keywords].map((value) => (
                <Chip
                  key={value}
                  label={value}
                  variant="outlined"
                  size="small"
                  sx={{
                    color: 'text.secondary',
                    borderRadius: 1,
                  }}
                />
              ))}
            </Stack>
          )}
        </Stack>
        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
            Attendees:
          </Typography>
          {isEdit ? (
            <Autocomplete
              id="free-solo-attendees"
              multiple
              freeSolo
              fullWidth
              value={attendees}
              onChange={(e, values) => {
                setAttendees(values)
              }}
              open={false}
              options={[]}
              renderInput={(params) => <TextField {...params} />}
            />
          ) : (
            <Stack
              direction="row"
              spacing={2}
              sx={{ flexWrap: 'wrap' }}
              useFlexGap
              divider={<Divider orientation="vertical" flexItem />}
            >
              {[...attendees].map((name) => (
                <Typography
                  key={name}
                  variant="subtitle2"
                  sx={{ color: 'text.secondary' }}
                >
                  {name}
                </Typography>
              ))}
            </Stack>
          )}
        </Stack>
      </Box>

      {/* Block note view */}
      <BlockNoteView
        editor={editor}
        editable={isEdit}
        theme={palette.mode}
        onChange={() => setBlocks(editor.document)}
      />
    </>
  )
}

export default MeetingPage
