function debounce<T extends (...args: any[]) => void>(
  cb: T,
  delay: number = 1000
) {
  let timeout: ReturnType<typeof setTimeout>

  return (...args: Parameters<T>): void => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      cb(...args)
    }, delay)
  }
}
export default debounce
