import React, {
  useState,
  useEffect,
  FC,
  PropsWithChildren,
  createContext,
  useContext,
} from 'react'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import useMediaQuery from '@mui/material/useMediaQuery'
import { dark, light } from '../theme'

interface ITheme {
  toggleTheme: () => void
}

const defaultValue: ITheme = {
  toggleTheme: () => {},
}

const ThemeToggleContext = createContext(defaultValue)

export const useThemeToggle = () => useContext(ThemeToggleContext).toggleTheme

const ThemeToggleProvider: FC<PropsWithChildren> = ({ children }) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const [mode, setMode] = useState<'light' | 'dark'>(
    (localStorage.getItem('themeMode') as any) ||
      (prefersDarkMode ? 'dark' : 'light')
  )

  useEffect(() => {
    const savedMode = localStorage.getItem('themeMode')
    if (savedMode) {
      setMode(savedMode as any)
    }
  }, [])

  const theme = React.useMemo(() => (mode === 'dark' ? dark : light), [mode])

  const toggleTheme = () => {
    const newMode = mode === 'light' ? 'dark' : 'light'
    setMode(newMode as any)
    localStorage.setItem('themeMode', newMode)
  }

  useEffect(() => {
    //@ts-ignore
    window.toggleTheme = toggleTheme
    // eslint-disable-next-line
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <ThemeToggleContext.Provider
        value={{
          toggleTheme: toggleTheme,
        }}
      >
        <CssBaseline />
        {children}
      </ThemeToggleContext.Provider>
    </ThemeProvider>
  )
}

export default ThemeToggleProvider
