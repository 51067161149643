import { Block } from '@blocknote/core'
import { useApi } from '../../providers/ApiProvider'

const API_BASE = '/api/prompts/'

export interface PromptResponse {
  id: string
  label: string
  blocks: Block[]
}

const usePromptApi = () => {
  const api = useApi()

  const getPrompts = async () => {
    const data = await api.get<PromptResponse[]>(API_BASE)
    return data.data
  }

  return {
    getPrompts,
  }
}

export default usePromptApi
