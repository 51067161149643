import { Close, Mic, StopCircle, Upload } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from '@mui/material'
import { FC, useEffect, useRef, useState } from 'react'
import useAudioFileApi from './api'
import { useMutation } from '@tanstack/react-query'
import { useTheme } from '@mui/material'

interface NoteProps {
  open: boolean
  close: () => void
}

const Note: FC<NoteProps> = ({ open, close }) => {
  const [isRecording, setIsRecording] = useState<boolean>(false)
  const [audioData, setAudioData] = useState<Blob[]>([])
  const [recordingTime, setRecordingTime] = useState<number>(0)

  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const mediaRecorderRef = useRef<MediaRecorder | null>(null)
  const animationFrameRef = useRef<number | null>(null)
  const timerIntervalRef = useRef<number | null>(null)

  const { palette } = useTheme()

  const { uploadFile } = useAudioFileApi()

  const uploadMutation = useMutation({
    mutationFn: uploadFile,
    onSuccess: () => {
      reset()
      close()
    },
  })

  const handleUpload = () => {
    if (audioData.length === 0) {
      console.error('No recording to upload')
      return
    }

    const audioBlob = new Blob(audioData, { type: 'audio/webm' })
    uploadMutation.mutate({ audio: audioBlob, duration: recordingTime / 60 })
  }

  const reset = () => {
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current)
    }
    if (timerIntervalRef.current) {
      clearInterval(timerIntervalRef.current)
    }
    setRecordingTime(0)
    setAudioData([])
  }

  useEffect(() => {
    return () => {
      reset()
    }
  }, [])

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      const mediaRecorder = new MediaRecorder(stream)
      mediaRecorderRef.current = mediaRecorder
      const audioContext = new (window.AudioContext ||
        //@ts-ignore
        window.webkitAudioContext)()
      const source = audioContext.createMediaStreamSource(stream)
      const analyser = audioContext.createAnalyser()
      analyser.fftSize = 256
      source.connect(analyser)

      const bufferLength = analyser.frequencyBinCount
      const dataArray = new Uint8Array(bufferLength)

      const draw = () => {
        const canvas = canvasRef.current
        if (!canvas) return

        const canvasCtx = canvas.getContext('2d')
        if (!canvasCtx) return

        const width = canvas.width
        const height = canvas.height

        analyser.getByteTimeDomainData(dataArray)

        canvasCtx.fillStyle = palette.background.default
        canvasCtx.fillRect(0, 0, width, height)
        canvasCtx.lineWidth = 2
        canvasCtx.strokeStyle = palette.text.primary
        canvasCtx.beginPath()

        const sliceWidth = (width * 1.0) / bufferLength
        let x = 0

        for (let i = 0; i < bufferLength; i++) {
          const v = dataArray[i] / 128.0
          const y = (v * height) / 2

          if (i === 0) {
            canvasCtx.moveTo(x, y)
          } else {
            canvasCtx.lineTo(x, y)
          }

          x += sliceWidth
        }

        canvasCtx.lineTo(width, height / 2)
        canvasCtx.stroke()

        animationFrameRef.current = requestAnimationFrame(draw)
      }

      mediaRecorder.ondataavailable = (event: BlobEvent) => {
        setAudioData((prevData) => [...prevData, event.data])
      }

      mediaRecorder.start()
      setIsRecording(true)
      draw()
      timerIntervalRef.current = window.setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1)
      }, 1000)
    } catch (error) {
      console.error('Error starting recording:', error)
    }
  }

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop()
      mediaRecorderRef.current.stream
        .getAudioTracks()
        .forEach(function (track) {
          track.stop()
        })
      setIsRecording(false)
    }
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current)
    }
    if (timerIntervalRef.current) {
      clearInterval(timerIntervalRef.current)
    }
  }

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`
  }

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      gap={1}
      flexDirection={'column'}
      className="flex flex-col items-center space-y-4"
    >
      <Box
        sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
      >
        <Typography variant="body1" fontWeight={500}>
          {isRecording
            ? `Recording: ${formatTime(recordingTime)}`
            : 'Ready to Record'}
        </Typography>
        <IconButton
          size="small"
          onClick={() => {
            close()
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <Box
        component={'canvas'}
        ref={canvasRef}
        width={300}
        height={100}
        sx={({ palette }) => ({
          border: `1px solid ${palette.text.primary}`,
          borderRadius: 2,
        })}
      />
      <Button
        onClick={isRecording ? stopRecording : startRecording}
        sx={({ palette }) => ({
          px: 4,
          py: 2,
          mt: 2,
          borderRadius: '99px',
          background: isRecording ? palette.error.main : palette.success.main,
          color:
            palette.mode === 'dark'
              ? palette.common.black
              : palette.common.white,
          '&:hover': {
            background: isRecording
              ? palette.error.light
              : palette.success.light,
          },
        })}
        startIcon={isRecording ? <StopCircle /> : <Mic />}
      >
        {isRecording ? <>Stop Recording</> : <>Start Recording</>}
      </Button>
      {audioData.length > 0 && (
        <>
          <Button
            onClick={handleUpload}
            disabled={uploadMutation.isPaused}
            color="secondary"
            variant="outlined"
            sx={{
              borderRadius: '99px',
            }}
            startIcon={<Upload />}
            endIcon={
              uploadMutation.isPending ? (
                <CircularProgress color="secondary" size={16} />
              ) : undefined
            }
          >
            {uploadMutation.isPending ? 'Uploading...' : 'Upload Recording'}
          </Button>
        </>
      )}
    </Box>
  )
}

export default Note
