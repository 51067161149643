import dayjs from 'dayjs'
import { columnTypeMap, columnWidthMap } from './const'
import { CustomColumnOptions, DataType, ExportOptions } from './db.typing'
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid'

const nameMapping: Record<string, string> = {
  app_ritter_case_management: 'Case Management',
  app_ritter_pantry: 'Pantry',
  app_ritter_ecw: 'ECW (Medical + BH)',
  app_ritter_wpc: 'Whole Person Care',
  app_ritter_safety_net: 'Safety Net',
  app_ritter_care_gaps_analysis: 'Analysis of Open Care Gaps',
  app_ritter_upd: 'Universal Patient Data',
  app_ritter_quality_statistics: 'Quality Statistics',
}

export const formatHeaderName = (columnName: string): string => {
  if (nameMapping[columnName]) {
    return nameMapping[columnName]
  }
  return columnName
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const getDefaultWidth = (dataType: DataType): number => {
  return columnWidthMap[dataType] || 150
}

export const getColumnType = (dataType: DataType): string => {
  return columnTypeMap[dataType] || 'string'
}

export const formatCellForExport = (
  value: any,
  column: GridColDef,
  mergedExportOptions: ExportOptions,
  customColumnOptions: Record<string, CustomColumnOptions>
): string => {
  if (value === null || value === undefined) return ''

  // Check for custom formatter
  if (mergedExportOptions.customFormatters?.[column.field]) {
    return mergedExportOptions.customFormatters[column.field](value)
  }

  // Check for custom column options
  const customOptions = customColumnOptions[column.field]
  if (customOptions) {
    switch (customOptions.renderer) {
      case 'date':
        const date = dayjs(new Date(value))
        return date.format(mergedExportOptions.dateFormat!)
      case 'boolean':
        return value ? 'Yes' : 'No'
      case 'currency':
        return typeof value === 'number' ? value.toFixed(2) : value.toString()
      case 'status':
        return value.toString()
      default:
        return value.toString()
    }
  }

  return value.toString()
}

export const convertToCSV = (
  rows: GridValidRowModel[],
  mergedExportOptions: ExportOptions,
  columns: GridColDef[],
  customColumnOptions: Record<string, CustomColumnOptions>
): string => {
  const exportableColumns = columns.filter(
    (col) => !mergedExportOptions.excludeColumns?.includes(col.field)
  )

  let csv = ''

  // Add headers
  if (mergedExportOptions.includeHeaders) {
    csv =
      exportableColumns
        .map((column) => `"${column.headerName || column.field}"`)
        .join(mergedExportOptions.delimiter) + '\n'
  }

  // Add rows
  csv += rows
    .map((row) =>
      exportableColumns
        .map(
          (column) =>
            `"${formatCellForExport(row[column.field], column, mergedExportOptions, customColumnOptions)}"`
        )
        .join(mergedExportOptions.delimiter)
    )
    .join('\n')

  return csv
}

// Download CSV file
export const downloadCSV = (
  csv: string,
  mergedExportOptions: ExportOptions
): void => {
  const blob = new Blob(['\uFEFF' + csv], { type: 'text/csv;charset=utf-8;' })
  const link = document.createElement('a')
  const url = URL.createObjectURL(blob)

  link.setAttribute('href', url)
  link.setAttribute('download', `${mergedExportOptions.fileName}.csv`)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const sortGridColumns = (
  columns: GridColDef[],
  preferredOrder: string[]
): GridColDef[] => {
  // Create a map to quickly look up the index of each column
  const columnIndexMap = columns.reduce(
    (map, col, index) => {
      map[col.field as string] = index
      return map
    },
    {} as { [key: string]: number }
  )

  // Sort the columns based on the preferred order
  return columns.sort((a, b) => {
    const aIndex = preferredOrder.indexOf(a.field as string)
    const bIndex = preferredOrder.indexOf(b.field as string)

    // If both columns are in the preferred order, sort by their original index
    if (aIndex !== -1 && bIndex !== -1) {
      return aIndex - bIndex
    }
    // If only one column is in the preferred order, put it first
    else if (aIndex !== -1) {
      return -1
    } else if (bIndex !== -1) {
      return 1
    }
    // If neither column is in the preferred order, sort by their original index
    else {
      return (
        columnIndexMap[a.field as string] - columnIndexMap[b.field as string]
      )
    }
  })
}
