import React, { useState, FC, useEffect } from 'react'
import {
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  useReactFlow,
  XYPosition,
} from '@xyflow/react'
import '@xyflow/react/dist/style.css'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'
import { type SxProps, type Theme } from '@mui/material'
import { ModuleCategory, ModuleItem, TextNode, TextNodeData } from './typing'
import IconRenderer from '../common/Icon'
import useWorkflowApi, { NodeCreationRequest, WorkflowDetails } from './api'
import { useQuery } from '@tanstack/react-query'
import Loader from '../Loader'
import CoreWorkflow from './Core'
import { useParams } from 'react-router'

// Module Types for Sidebar
const moduleTypes: ModuleCategory[] = [
  {
    category: 'Node',
    items: [
      {
        icon: 'TextFormat',
        label: 'Text',
        color: 'primary.light',
        description: 'Simple text and icon node',
        type: 'text',
      },
    ],
  },
]

const toolbarButtonSx: SxProps<Theme> = {
  height: 32,
  textTransform: 'none',
}

const drawerWidth = 340

// Main Workflow Builder Component
const WorkflowContent: React.FC<{ data: WorkflowDetails }> = ({ data }) => {
  const [nodes, setNodes] = useNodesState(data.nodes)
  const [edges, setEdges] = useEdgesState(data.edges)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [showDrawer, setShowDrawer] = useState<boolean>(false)
  const { getNodes } = useReactFlow()

  useEffect(() => {
    setNodes(data.nodes)
    setEdges(data.edges)
  }, [data])

  const workflow_id = data.id

  const { node, workflow } = useWorkflowApi()

  const getNewNodePosition = (): XYPosition => {
    const existingNodes = getNodes()
    let maxX = 100
    let maxY = 100

    if (existingNodes.length > 0) {
      maxX = Math.max(...existingNodes.map((node) => node.position.x)) + 300
      maxY = Math.max(...existingNodes.map((node) => node.position.y))
    }

    return { x: maxX, y: maxY }
  }

  const createNode = async (item: ModuleItem) => {
    if (item.type === 'text') {
      const position = getNewNodePosition()
      const newNodeRequest: NodeCreationRequest<TextNodeData> = {
        type: item.type,
        workflow_id: workflow_id,
        position: position,
        data: {
          workflow_id: workflow_id,
          label: 'Unititled',
          description: 'Unititled',
          color: item.color,
          icon: 'Edit',
          type: item.type,
        },
      }
      const nodeData = await node.add(newNodeRequest)
      const newNode: TextNode = {
        id: nodeData.id,
        type: nodeData.type,
        position: nodeData.position,
        data: nodeData.data,
      }
      setNodes((nds) => [...nds, newNode])
      setShowDrawer(false)
    }
  }

  const onSave = () => {
    setNodes((nds) =>
      nds.map((node) => {
        return {
          ...node,
          data: {
            ...node.data,
            isEditing: false,
          },
        }
      })
    )
    workflow.update(workflow_id, { nodes, edges })
  }

  const handleCloseAlert = (): void => {
    setShowAlert(false)
  }

  return (
    <Box
      sx={{
        height: 'calc(100vh - 120px)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <AppBar position="static" color="default" elevation={1}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 0, mr: 4 }}>
            {data.name || 'Canvas'}
          </Typography>

          <Button
            variant="outlined"
            startIcon={<IconRenderer iconName="Add" />}
            size="small"
            color="secondary"
            sx={{ ...toolbarButtonSx, mr: 'auto' }}
            onClick={() => setShowDrawer(true)}
          >
            Add Module
          </Button>

          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button
              variant="outlined"
              startIcon={<IconRenderer iconName="Save" />}
              size="small"
              color="secondary"
              onClick={onSave}
              sx={toolbarButtonSx}
            >
              Save
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <CoreWorkflow
        edges={edges}
        nodes={nodes}
        setEdges={setEdges}
        setNodes={setNodes}
      />
      <Drawer
        anchor="right"
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          {moduleTypes.map((category) => (
            <React.Fragment key={category.category}>
              <Typography
                variant="subtitle2"
                sx={{ px: 2, py: 1, fontWeight: 'bold' }}
              >
                {category.category}
              </Typography>
              <List>
                {category.items.map((item) => (
                  <ListItem
                    key={item.label}
                    onClick={() => createNode(item)}
                    sx={{
                      py: 0.5,
                      '&:hover': {
                        bgcolor: 'action.hover',
                      },
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 40 }}>
                      <IconRenderer iconName={item.icon} />
                    </ListItemIcon>
                    <ListItemText
                      primary={item.label}
                      secondary={item.description}
                    />
                  </ListItem>
                ))}
              </List>
              <Divider />
            </React.Fragment>
          ))}
        </Box>
      </Drawer>

      <Snackbar
        open={showAlert}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseAlert}
          severity="success"
          sx={{ width: '100%' }}
        >
          <Typography variant="subtitle2">Workflow Executed</Typography>
          <Typography variant="body2">
            Your workflow has been executed successfully.
          </Typography>
        </Alert>
      </Snackbar>
    </Box>
  )
}

const LoadWorkFlow: React.FC<{ workflowId: string }> = ({ workflowId }) => {
  const { workflow } = useWorkflowApi()

  const { data, isLoading, isError } = useQuery({
    queryKey: ['workflow', workflowId],
    queryFn: () => workflow.get(workflowId),
  })

  if (isLoading || !data) {
    return <Loader />
  }

  if (isError) {
    return <>Error</>
  }

  return <WorkflowContent data={data} />
}

// Wrapper component to provide ReactFlow context
const WorkflowBuilder: React.FC = () => {
  const params = useParams()

  return (
    <ReactFlowProvider>
      <LoadWorkFlow workflowId={params.id || ''} />
    </ReactFlowProvider>
  )
}

export default WorkflowBuilder
