import { useApi } from '../../providers/ApiProvider'

export interface CallLogsResponse {
  rows: CallRecord[]
}

export interface CallRecord {
  id: string
  created_at: string
  organization: any
  call_id: string
  start_date_time?: string
  end_date_time?: string
  duration?: number
  type: string
  cost: string
  cost_denomination: string
  end_reason?: string
  call_outcome: any
  call_reason: any
  callee_number?: string
  callee_name: any
  callee_email: any
  kpis?: string
  action_items: any
  notes: any
  url?: string
  assistant_id: string
}

const useCallLogsApi = () => {
  const api = useApi()

  const getCalls = async () => {
    const data = await api.post<CallLogsResponse>(`/api/table/call_logs/data`, {
      filterModel: { items: [] },
      paginationModel: {
        page: 0,
        pageSize: 250,
      },
      sortModel: [],
    })
    return data.data?.rows || []
  }

  return {
    getCalls,
  }
}

export default useCallLogsApi
