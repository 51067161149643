import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'

const NotFoundPage = () => {
  const navigate = useNavigate()
  const [fadeIn, setFadeIn] = useState(false)

  useEffect(() => {
    setFadeIn(true)
  }, [])

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      sx={{
        bgcolor: 'background.default',
        color: 'text.primary',
        textAlign: 'center',
        transition: 'opacity 1s ease-in-out',
        opacity: fadeIn ? 1 : 0, // Fade-in effect
      }}
    >
      {/* 404 Title */}
      <Typography variant="h1" fontWeight="bold" gutterBottom color="error">
        404
      </Typography>

      {/* Description Text */}
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          animation: 'fadeIn 2s ease-in-out',
          '@keyframes fadeIn': {
            from: { opacity: 0 },
            to: { opacity: 1 },
          },
        }}
      >
        The page you're looking for can't be found.
      </Typography>

      {/* Back to Home Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate('/')}
        sx={{ mt: 4 }}
      >
        Back to Home
      </Button>
    </Box>
  )
}

export default NotFoundPage
