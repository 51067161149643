import { Chip } from '@mui/material'
import TableEditor from '../TableEditor'
import MuiLink from '@mui/material/Link'
import { Link } from 'react-router-dom'
import { getColorForType } from '../CallLogs/util'
import { OpenInNew } from '@mui/icons-material'

const CallLog = () => {
  return (
    <TableEditor
      readonly
      hideHeader
      tableName="demo_outreach_logs"
      defaultSortOption={[{ field: 'status', sort: 'asc' }]}
      columOverrides={[
        {
          field: 'Interaction Log',
          width: 150,
          headerName: 'Interaction Log',
          renderCell: (params) => {
            return (
              <MuiLink
                color="text.secondary"
                component={Link}
                sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                to={`detail/${params.row.id}`}
              >
                View Log{' '}
                <OpenInNew sx={{ color: 'text.secondary', fontSize: '14px' }} />
              </MuiLink>
            )
          },
        },
        { field: 'Outreach Campaign', width: 150 },
        {
          field: 'name',
          width: 150,
        },
        {
          field: 'number',
          width: 130,
        },
        {
          field: 'date',
          width: 100,
        },
        {
          field: 'time',
          width: 100,
        },
        {
          field: 'duration',
          width: 100,
        },
        {
          field: 'status',
          width: 120,
          renderCell: (e) => (
            <Chip
              label={e.value || '--'}
              size={'small'}
              sx={(theme) => ({
                background: getColorForType(
                  e.value || '--',
                  theme.palette.mode
                ),
              })}
            />
          ),
        },
      ]}
    />
  )
}

export default CallLog
