import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Loader from '../Loader'
import { GridColDef } from '@mui/x-data-grid'
import { Outlet, useNavigate, useParams } from 'react-router'
import { useQuery } from '@tanstack/react-query'
import { Protect, useOrganization } from '@clerk/clerk-react'
import { FC, useMemo, useState } from 'react'
import CalendarTodayOutlined from '@mui/icons-material/CalendarTodayOutlined'
import AccessTimeOutlined from '@mui/icons-material/AccessTimeOutlined'
import dayjs from 'dayjs'
import useMeetingsApi from './api'
import Grid from '../common/Grid'
import GridContainer from '../common/GridContainer'

const LIMIT = 100

const cols: GridColDef[] = [
  {
    field: 'Name',
    headerName: 'MEETING',
    cellClassName: 'grid-link',
    minWidth: 350,
  },
  {
    field: 'date',
    headerName: 'DATE',
    minWidth: 200,
    cellClassName: 'small-cell',
    renderCell: (e) => (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
        <CalendarTodayOutlined
          sx={{ color: 'text.secondary', fontSize: '14px' }}
        />
        {e.value}
      </Box>
    ),
  },
  {
    field: 'time',
    headerName: 'TIME',
    minWidth: 200,
    cellClassName: 'small-cell',
    renderCell: (e) => (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
        <AccessTimeOutlined
          sx={{ color: 'text.secondary', fontSize: '14px' }}
        />
        {e.value}
      </Box>
    ),
  },
  {
    field: 'duration',
    headerName: 'DURATION',
    cellClassName: 'small-cell',
    minWidth: 200,
    valueFormatter: (e) => (e < 1 ? `${e} min` : `${e} mins`),
  },
]

const Meetings: FC<{ my: boolean }> = ({ my }) => {
  const params = useParams()
  const [page, setPage] = useState(0)
  const { organization } = useOrganization()
  const { getAllMeetings, getMyMeetings } = useMeetingsApi()
  const isHelpcareHq = useMemo(
    () => organization?.slug === 'helpcare',
    [organization?.slug]
  )
  const navigate = useNavigate()

  const { data, isLoading, isError } = useQuery({
    queryKey: my ? ['my-meetings'] : ['meetings', `page-${page}`],
    enabled: my || isHelpcareHq,
    queryFn: () => (my ? getMyMeetings() : getAllMeetings(page * LIMIT, LIMIT)),
  })

  const tableRows = useMemo(
    () =>
      (data || []).map((e) => {
        const data = {
          id: e.id,
          Name: e.meta.label,
          duration: e.meta.duration,
        } as any
        for (const row of e.properties) {
          if (row.key === 'Event time') {
            try {
              const date = dayjs(new Date(row.value))
              data.date = date.format('ddd, MMM D')
              data.time = date.format('h:mm A')
            } catch (e) {
              data[row.key] = row.value
            }
          } else {
            data[row.key] = row.value
          }
        }
        return data
      }),
    [data]
  )

  if (!my && !isHelpcareHq) {
    return (
      <Typography variant="h2">
        You are not allowed to access this page
      </Typography>
    )
  }

  if (isLoading) {
    return <Loader />
  }

  if (isError) {
    return <Box>Error</Box>
  }

  return (
    <Protect
      condition={(has: any) =>
        (my ? true : has({ role: 'org:global_admin' })) &&
        has({ permission: 'org:feature:read_meeting_notes' })
      }
      fallback={
        <Typography variant="h2">
          You are not allowed to access this page
        </Typography>
      }
    >
      {params.id ? (
        <Outlet />
      ) : (
        <GridContainer width="100%">
          <Grid
            hideFooter
            checkboxSelection
            disableRowSelectionOnClick={true}
            slots={{
              noRowsOverlay: () => (
                <Box display={'flex'} justifyContent={'center'} pt={3}>
                  <Typography variant="subtitle2" color="text.primary">
                    To see your meting notes be sure to invite
                    notetaker.hanna@helpcare.ai to your meetings.
                  </Typography>
                </Box>
              ),
            }}
            columns={cols}
            rows={tableRows}
            rowHeight={40}
            autosizeOnMount={true}
            onCellClick={(e) => {
              if (e.field === 'Name') {
                navigate(`${e.id}`)
              }
            }}
            paginationModel={{
              page: page,
              pageSize: LIMIT,
            }}
            onPaginationModelChange={(e) => {
              setPage(e.page)
            }}
          />
        </GridContainer>
      )}
    </Protect>
  )
}

export default Meetings
