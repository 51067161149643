import Box, { BoxProps } from '@mui/material/Box'
import { FC } from 'react'
const Logo: FC<BoxProps<'svg'>> = (props) => (
  <Box
    component={'svg'}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 136 136"
    fill="none"
    {...props}
  >
    <Box
      component={'path'}
      sx={({ palette }) => ({
        fill: palette.mode === 'dark' ? '#861F99' : '#DA05FF',
      })}
      d="M0 34C0 15.222 15.222 0 34 0s34 15.222 34 34v34H34C15.222 68 0 52.778 0 34ZM68 68h53.333c3.41 0 5.115 0 6.514.375a11 11 0 0 1 7.778 7.778c.375 1.399.375 3.104.375 6.514V102c0 18.778-15.222 34-34 34s-34-15.222-34-34V68ZM0 121.333c0-12.399 0-18.599 1.363-23.686a40 40 0 0 1 28.284-28.284C34.734 68 40.934 68 53.333 68H68v50.4c0 6.161 0 9.241-1.199 11.594a11 11 0 0 1-4.807 4.807C59.64 136 56.56 136 50.4 136H14.667c-3.41 0-5.115 0-6.514-.375a11 11 0 0 1-7.778-7.778C0 126.448 0 124.743 0 121.333ZM68 34c0-18.778 15.222-34 34-34s34 15.222 34 34-15.222 34-34 34-34-15.222-34-34Z"
    />
  </Box>
)
export default Logo
