import {
  FC,
  PropsWithChildren,
  ReactNode,
  forwardRef,
  useMemo,
  useState,
} from 'react'
import { useNavigate } from 'react-router'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { RichTreeView, useTreeItem2, useTreeViewApiRef } from '@mui/x-tree-view'
import {
  TreeItem2,
  TreeItem2Props,
  TreeItem2Label,
} from '@mui/x-tree-view/TreeItem2'
import AddIcon from '@mui/icons-material/Add'
import MoreHoriz from '@mui/icons-material/MoreHoriz'
import usePagesApi, { Page } from './api'
import Box from '@mui/material/Box'
import MuiMenu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import DeleteForever from '@mui/icons-material/DeleteForever'
import RestoreFromTrash from '@mui/icons-material/RestoreFromTrash'
import HighQuality from '@mui/icons-material/HighQuality'
import { Protect, useAuth, useOrganization } from '@clerk/clerk-react'
import MyMeetings from '../icons/MyMeetings'
import CustomTooltip from '../CustomTooltip/CustomTooltip'
import Assessment from '@mui/icons-material/Assessment'
import useReportsApi from '../MyData/Reports/api'
import useMeetingsApi from '../Meetings/api'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  ListItemText,
  TextField,
} from '@mui/material'
import PendingActions from '@mui/icons-material/PendingActions'
import { Call, SchemaOutlined } from '@mui/icons-material'
import useWorkflowApi from '../Workflow/api'

interface CustomLabelProps {
  expandable?: boolean
  doc_id?: string
  deleted: boolean
  showOptions?: boolean
  icon?: ReactNode
}

interface MenuProps {
  anchorEl: any
  handleClose: () => void
  doc_id?: string
  deleted: boolean
}

const recursiveMapPage = (page: Page[]): any[] => {
  return page.map((e) => ({
    id: e.id,
    label: e.label,
    url: `/pages/${e.id}`,
    children: e.children?.length ? recursiveMapPage(e.children) : [],
    showOptions: true,
  }))
}

const Menu: FC<MenuProps> = ({ anchorEl, handleClose, doc_id, deleted }) => {
  const { deletePage, restorePage } = usePagesApi()
  const open = Boolean(anchorEl)
  const navigate = useNavigate()

  return (
    <MuiMenu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      slotProps={{
        paper: {
          elevation: 2,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {deleted ? (
        <>
          <MenuItem
            dense
            onClick={() => {
              if (doc_id) {
                restorePage(doc_id, true).then(() => {
                  navigate('/pages')
                })
              }
            }}
          >
            <ListItemIcon color="error">
              <RestoreFromTrash color="warning" fontSize="small" />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }}>
              Restore
            </ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (doc_id) {
                deletePage(doc_id, true, true).then(() => {
                  navigate('/pages')
                })
              }
            }}
          >
            <ListItemIcon color="error">
              <DeleteForever color="error" fontSize="small" />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }}>
              Delete Forever
            </ListItemText>
          </MenuItem>
        </>
      ) : (
        <MenuItem
          dense
          onClick={() => {
            if (doc_id) {
              deletePage(doc_id, false, true).then(() => {
                navigate('/pages')
              })
            }
          }}
        >
          <ListItemIcon color="error">
            <DeleteForever color="error" fontSize="small" />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }}>
            Delete
          </ListItemText>
        </MenuItem>
      )}
    </MuiMenu>
  )
}

const CustomLabel: FC<PropsWithChildren<CustomLabelProps>> = ({
  children,
  doc_id,
  expandable,
  deleted,
  showOptions,
  icon,
  ...other
}) => {
  const { createPage } = usePagesApi()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <TreeItem2Label {...other}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          my: 0.25,
        }}
      >
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {icon}

          <CustomTooltip title={children}>
            <Typography
              variant="subtitle2"
              sx={{ color: 'text.secondary' }}
              noWrap
            >
              {children}
            </Typography>
          </CustomTooltip>
        </Box>
        {showOptions && (
          <Protect permission="org:feature:write_pages">
            <Box display={'flex'} gap={1}>
              <IconButton
                size="small"
                sx={{
                  height: 16,
                  width: 16,
                }}
                onClick={(e) => {
                  e.stopPropagation()
                  handleClick(e)
                }}
              >
                <MoreHoriz
                  height={16}
                  width={16}
                  sx={{ color: 'text.secondary' }}
                />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                handleClose={handleClose}
                deleted={deleted}
                doc_id={doc_id}
              />
              {!deleted && (
                <IconButton
                  size="small"
                  sx={{
                    height: 16,
                    width: 16,
                  }}
                  onClick={(e) => {
                    createPage(doc_id, true).then((e) => {
                      navigate('/pages/' + e.id)
                    })
                  }}
                >
                  <AddIcon
                    height={16}
                    width={16}
                    sx={{ color: 'text.secondary' }}
                  />
                </IconButton>
              )}
            </Box>
          </Protect>
        )}
      </Box>
    </TreeItem2Label>
  )
}

const CustomItem: FC<TreeItem2Props> = forwardRef(({ ...props }, ref) => {
  const { id, itemId, label, disabled, children } = props
  const {
    publicAPI,
    //@ts-ignore
  } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref })

  //@ts-ignore
  const { id: doc_id, deleted, showOptions, icon } = publicAPI.getItem(itemId)
  return (
    <TreeItem2
      {...props}
      label={
        <CustomLabel
          doc_id={doc_id}
          deleted={deleted}
          showOptions={showOptions}
          icon={icon}
        >
          {props.label}
        </CustomLabel>
      }
    />
  )
})

const PageTree: FC = () => {
  const { organization } = useOrganization()
  const { has } = useAuth()
  const { getMyMeetings } = useMeetingsApi()
  const { getAllReports } = useReportsApi()
  const { getAllPages, createPage } = usePagesApi()
  const { workflow } = useWorkflowApi()

  const treeRef = useTreeViewApiRef()
  const trashRef = useTreeViewApiRef()
  const canvasRef = useTreeViewApiRef()
  const isHelpcareHq = useMemo(
    () => organization?.slug === 'helpcare',
    [organization?.slug]
  )
  const { data } = useQuery({
    queryKey: ['my-meetings'],
    queryFn: () => getMyMeetings(),
    enabled: organization !== null,
  })

  const { data: reports } = useQuery({
    queryKey: ['my-reports'],
    queryFn: () => getAllReports(),
    enabled: organization !== null,
  })

  const myCalls = useMemo(() => {
    return isHelpcareHq
      ? [
          {
            id: 'call-logs',
            url: 'call-logs',
            label: 'My Calls',
            icon: (
              <Call
                sx={{ mr: 1, color: 'text.secondary', height: 20, width: 20 }}
              />
            ),
          },
        ]
      : []
  }, [isHelpcareHq])

  const showMyData = useMemo(
    () =>
      (reports || [])?.length > 0 &&
      has &&
      has({ permission: 'org:feature:read_data_view' }),
    [reports, has]
  )

  const myData = useMemo(() => {
    return showMyData
      ? [
          {
            id: 'org-data',
            url: '/my-data',
            label: 'My Data',
            icon: (
              <Assessment
                fontSize="small"
                sx={{ mr: 1, color: 'text.secondary' }}
              />
            ),
            children: (reports || []).map((e) => {
              return {
                id: e.id,
                url: `/my-data/${e.id}`,
                label: e.title,
                showOptions: false,
              }
            }),
          },
        ]
      : []
  }, [showMyData, reports])

  const helpcareHQ = useMemo(
    () =>
      isHelpcareHq
        ? [
            {
              id: 'hc-hq-root',
              url: '/meetings',
              label: 'Helpcare HQ',
              icon: (
                <HighQuality
                  fontSize="small"
                  sx={{ mr: 1, color: 'text.secondary' }}
                />
              ),
              children: [
                {
                  id: 'meetings',
                  url: '/meetings',
                  label: 'Meetings',
                  showOptions: false,
                },
              ],
            },
          ]
        : [],
    [isHelpcareHq]
  )

  const myMeetings = useMemo(
    () =>
      organization !== null
        ? [
            ...helpcareHQ,
            {
              id: 'my-meetings',
              url: '/my-meetings',
              label: 'My Meetings',
              icon: (
                <MyMeetings
                  height={20}
                  width={20}
                  sx={{ mr: 1, color: 'text.secondary' }}
                />
              ),
              children: (data || []).map((e) => ({
                id: e.id,
                url: `/my-meetings/${e.id}`,
                label: e.meta.label,
                showOptions: false,
              })),
            },
            ...myData,
          ]
        : [],
    [data, organization, helpcareHQ, myData]
  )

  const { data: activePages } = useQuery({
    queryKey: ['pages', organization?.id],
    queryFn: () => getAllPages(false),
  })

  const { data: deletedPages } = useQuery({
    queryKey: ['pages-trash', organization?.id],
    queryFn: () => getAllPages(true),
  })

  const { data: workflows } = useQuery({
    queryKey: ['workflow'],
    queryFn: () => workflow.getAll(),
  })

  const treeData = useMemo(() => {
    return [
      {
        id: 'actions',
        url: 'actions',
        label: 'My Actions',
        icon: (
          <PendingActions
            sx={{ mr: 1, color: 'text.secondary', height: 20, width: 20 }}
          />
        ),
      },
      ...myMeetings,
      ...myCalls,
      activePages?.length
        ? {
            id: 'my-pages',
            label: 'Knowledge base',
            icon: (
              <MyMeetings
                height={20}
                width={20}
                sx={{ mr: 1, color: 'text.secondary' }}
              />
            ),
            children: recursiveMapPage(activePages || []),
          }
        : null,
    ].filter((e) => e != null)
  }, [myMeetings, activePages, myCalls])

  const workflowData = useMemo(() => {
    return workflows?.length
      ? [
          {
            id: 'workflow-root',
            label: 'Workflow',
            icon: (
              <SchemaOutlined
                height={20}
                width={20}
                sx={{ mr: 1, color: 'text.secondary' }}
              />
            ),
            children: workflows.map((e: any) => ({
              id: e.id,
              label: e.name,
              url: `/auto/${e.id}`,
              showOptions: false,
            })),
          },
        ]
      : []
  }, [workflows])

  const trashData = useMemo(() => {
    return deletedPages?.length
      ? [
          {
            id: 'trash-root',
            label: 'Trash',
            icon: (
              <DeleteForever
                sx={{ mr: 1, color: 'text.secondary', height: 20, width: 20 }}
              />
            ),
            children: deletedPages.map((e) => ({
              id: e.id,
              label: e.label,
              deleted: e.deleted,
              showOptions: true,
            })),
          },
        ]
      : []
  }, [deletedPages])

  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [open, setOpen] = useState(false)

  const onSave = async (name: string) => {
    const data = await workflow.create({ name: name })
    queryClient.refetchQueries({
      queryKey: ['workflow'],
    })
    navigate(`/auto/${data.id}`)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Box sx={{ mt: 3 }}>
      <RichTreeView
        apiRef={treeRef}
        sx={({ palette }) => ({
          '& .MuiTreeItem-content.Mui-selected': {
            background: palette.mode === 'light' ? '#fff' : undefined,
          },
        })}
        items={treeData || []}
        slots={{
          item: CustomItem,
        }}
        expansionTrigger={'iconContainer'}
        onItemClick={(_, id) => {
          const item = treeRef.current?.getItem(id)
          if (item.url) {
            navigate(item.url)
          }
        }}
      />
      <Divider />
      <Protect permission="org:feature:write_pages">
        <Button
          endIcon={<AddIcon />}
          sx={{
            textTransform: 'none',
            color: 'text.secondary ',
            width: '100%',
            justifyContent: 'space-between',
            px: 1,
          }}
          onClick={() => {
            createPage().then((e) => {
              navigate('/pages/' + e.id)
            })
          }}
        >
          <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
            New page
          </Typography>
        </Button>
      </Protect>
      <Divider />

      <RichTreeView
        apiRef={canvasRef}
        sx={({ palette }) => ({
          '& .MuiTreeItem-content.Mui-selected': {
            background: palette.mode === 'light' ? '#fff' : undefined,
          },
        })}
        items={workflowData || []}
        slots={{
          item: CustomItem,
        }}
        expansionTrigger={'iconContainer'}
        onItemClick={(_, id) => {
          const item = canvasRef.current?.getItem(id)
          if (item?.url) {
            navigate(item.url)
          }
        }}
      />

      <Divider />
      {/* <Protect permission="org:feature:write_pages"> */}
      <Button
        endIcon={<AddIcon />}
        sx={{
          textTransform: 'none',
          color: 'text.secondary ',
          width: '100%',
          justifyContent: 'space-between',
          px: 1,
        }}
        onClick={handleClickOpen}
      >
        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
          New canvas
        </Typography>
      </Button>
      {/* </Protect> */}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            const formData = new FormData(event.currentTarget)
            const formJson = Object.fromEntries((formData as any).entries())
            const name = formJson.name
            onSave(name)
            handleClose()
          },
        }}
      >
        <DialogTitle>Create Canvas</DialogTitle>
        <DialogContent>
          <TextField
            autoComplete="off"
            autoFocus
            required
            margin="dense"
            id="canvas-name"
            name="name"
            label="Name"
            size="small"
            fullWidth
            variant="standard"
            helperText="Enter the name of canvas"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Create</Button>
        </DialogActions>
      </Dialog>
      <Divider />

      <RichTreeView
        apiRef={trashRef}
        sx={({ palette }) => ({
          '& .MuiTreeItem-content.Mui-selected': {
            background: palette.mode === 'light' ? '#fff' : undefined,
          },
        })}
        items={trashData || []}
        slots={{
          item: CustomItem,
        }}
        expansionTrigger={'iconContainer'}
        onItemClick={(_, id) => {
          const item = trashRef.current?.getItem(id)
          if (item?.url) {
            navigate(item.url)
          }
        }}
      />
    </Box>
  )
}

export default PageTree
