import { styled } from '@mui/material'
import { DataGrid, gridClasses } from '@mui/x-data-grid'
import { green, red } from '../../theme/color'

const Grid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  '& .MuiDataGrid-columnHeaderTitle': {
    color: theme.palette.text.secondary,
    display: 'block',
    ...theme.typography.subtitle3,
  },
  '& .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus':
    {
      outline: 'none',
    },
  '& .MuiDataGrid-checkboxInput': {
    visibility: 'hidden',
  },
  '& .MuiTablePagination-root': {
    overflow: 'hidden',
  },
  '& .MuiTablePagination-toolbar': {
    overflow: 'hidden',
  },
  [`.${gridClasses.cell}.high`]: {
    backgroundColor: red[400],
    color: '#1a3e72',
  },
  [`.${gridClasses.cell}.medium`]: {
    backgroundColor: red[200],
    color: '#1a3e72',
  },
  [`.${gridClasses.cell}.low`]: {
    backgroundColor: green[300],
    color: '#1a3e72',
  },
}))

export default Grid
