import { Protect } from '@clerk/clerk-react'
import Box from '@mui/material/Box'
import ErrorBoundary from '../ErrorBoundary'
import useHelpCenterApi from './api'
import { useQuery } from '@tanstack/react-query'
import Loader from '../Loader'
import HelpCenterPage from './HelpCenterPage'

const Helpcenter = () => {
  const { getHelpCenter } = useHelpCenterApi()

  const { data, isLoading } = useQuery({
    queryKey: ['help-center'],
    queryFn: getHelpCenter,
  })

  if (isLoading) {
    return (
      <Box sx={{ mt: 8 }}>
        <Loader />
      </Box>
    )
  }
  if (!data) {
    return (
      <Box sx={{ mt: 8 }}>
        <Loader />
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        '& .bn-default-styles': {
          fontFamily: 'Lato,sans-serif',
        },
      }}
    >
      <Protect permission="org:feature:read_help_center">
        <Box maxWidth={'lg'}>
          <ErrorBoundary>
            <HelpCenterPage data={data} />
          </ErrorBoundary>
        </Box>
      </Protect>
    </Box>
  )
}

export default Helpcenter
