import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import '@fontsource/lato/400.css'
import '@fontsource/lato/700.css'
import { PostHogProviderWrapper } from './providers/PostHogProviderWrapper'
import '@xyflow/react/dist/style.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <PostHogProviderWrapper>
      <App />
    </PostHogProviderWrapper>
  </React.StrictMode>
)
