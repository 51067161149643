import React from 'react'
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { CustomToolbarProps } from './db.typing'
import Download from '@mui/icons-material/Download'

const CustomToolbar: React.FC<CustomToolbarProps> = ({
  onExport,
  isExporting,
}) => (
  <GridToolbarContainer
    sx={(theme) => ({
      borderBottom: `1px solid ${theme.palette.divider}`,
    })}
  >
    <GridToolbarColumnsButton
      slotProps={{
        button: {
          color: 'secondary',
        },
      }}
    />
    <GridToolbarFilterButton
      slotProps={{
        button: {
          color: 'secondary',
        },
      }}
    />
    <GridToolbarExport
      slotProps={{
        button: {
          color: 'secondary',
        },
      }}
    />
    <Button
      color="secondary"
      startIcon={isExporting ? <CircularProgress size={20} /> : <Download />}
      onClick={onExport}
      disabled={isExporting}
    >
      Export All Data
    </Button>
  </GridToolbarContainer>
)

export default CustomToolbar
