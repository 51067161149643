import { SvgIconProps } from '@mui/material'
import { ICustomNode } from '../../CustomNode/typing'
import AssignmentInd from '@mui/icons-material/AssignmentInd'
import Chair from '@mui/icons-material/Chair'
import Favorite from '@mui/icons-material/Favorite'
import InsertInvitation from '@mui/icons-material/InsertInvitation'
import People from '@mui/icons-material/People'
import RoomService from '@mui/icons-material/RoomService'
import Storage from '@mui/icons-material/Storage'
import Warning from '@mui/icons-material/WarningRounded'
import CropFreeSharpIcon from '@mui/icons-material/CropFreeSharp'
import AngelicaToolip from './Tooltip/Angelica'
import { David } from './Tooltip/David'

export const initialVisible = false

export const delay = 700

export const commonIconProps: SvgIconProps = {
  color: 'secondary',
}

export const initialNodes: ICustomNode[] = [
  {
    id: 'n1',
    type: 'custom',
    position: { x: 100, y: 200 },
    data: {
      label: '3,046',
      visible: initialVisible,
      icon: <Storage {...commonIconProps} />,
      caption: 'Raw patient records',
      action: {
        top: {
          secondaryIcon: (
            <Warning
              color={'warning'}
              sx={{ fontSize: '14px', cursor: 'pointer' }}
            />
          ),
          tooltip: <David />,
        },
      },
    },
  },
  {
    id: 'n2',
    type: 'custom',
    position: { x: 300, y: 200 },
    data: {
      label: '2,637',
      caption: 'Patients',
      visible: initialVisible,
      icon: <People {...commonIconProps} />,
    },
  },
  {
    id: 'n3',
    type: 'custom',
    position: { x: 500, y: 200 },
    data: {
      label: '2,242',
      caption: 'Outreach Pending',
      visible: initialVisible,
      icon: <Chair {...commonIconProps} />,
      action: {
        top: {
          secondaryIcon: (
            <Warning
              color={'warning'}
              sx={{ fontSize: '14px', cursor: 'pointer' }}
            />
          ),
          icon: <CropFreeSharpIcon />,
          tooltip: <AngelicaToolip />,
        },
      },
    },
  },
  {
    id: 'n4',
    type: 'custom',
    position: { x: 700, y: 200 },
    data: {
      label: '1,108',
      caption: 'Appointments',
      visible: initialVisible,
      icon: <InsertInvitation {...commonIconProps} />,
      action: {
        top: {
          icon: <CropFreeSharpIcon />,
        },
      },
    },
  },
  {
    id: 'n5',
    type: 'custom',
    position: { x: 900, y: 200 },
    data: {
      label: '12',
      caption: 'Providers',
      visible: initialVisible,
      icon: <AssignmentInd {...commonIconProps} />,
      action: {
        bottom: {
          icon: <CropFreeSharpIcon />,
        },
      },
    },
  },
  {
    id: 'n6',
    type: 'custom',
    position: { x: 1100, y: 200 },
    data: {
      label: '23',
      caption: 'Services',
      visible: initialVisible,
      icon: <RoomService {...commonIconProps} />,
      action: {
        bottom: {
          icon: <CropFreeSharpIcon />,
        },
      },
    },
  },
  {
    id: 'n7',
    type: 'custom',
    position: { x: 1300, y: 200 },
    data: {
      label: '483 / 2,053',
      caption: 'Care Gaps Closed',
      visible: initialVisible,
      icon: <Favorite {...commonIconProps} />,
      action: {
        top: {
          icon: <CropFreeSharpIcon />,
        },
      },
    },
  },
]
