import Box, { BoxProps } from '@mui/material/Box'
import { FC } from 'react'
const Logo: FC<BoxProps<'svg'>> = (props) => (
  <Box
    component={'svg'}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 20"
    fill="none"
    {...props}
  >
    <Box component={'path'} d="M20.5 0H0.5V20H20.5V0Z" />
    <Box
      component={'path'}
      sx={({ palette }) => ({ fill: palette.text.secondary })}
      d="M4.58618 18.147H16.4038C18.3008 18.147 19.3005 17.1558 19.3005 15.2844V4.90235C19.3005 3.031 18.3008 2.03125 16.4038 2.03125H4.58618C2.69776 2.03125 1.68945 3.031 1.68945 4.90235V15.2844C1.68945 17.1558 2.69776 18.147 4.58618 18.147ZM4.64599 16.25C3.96241 16.25 3.59497 15.9082 3.59497 15.1819V7.4231C3.59497 6.70532 3.96241 6.36352 4.64599 6.36352H16.3354C17.019 6.36352 17.395 6.70532 17.395 7.4231V15.1819C17.395 15.9082 17.019 16.25 16.3354 16.25H4.64599ZM6.09863 9.36279H14.9084C15.2246 9.36279 15.4553 9.11499 15.4553 8.80737C15.4553 8.49975 15.2246 8.2605 14.9084 8.2605H6.09863C5.77393 8.2605 5.54322 8.49975 5.54322 8.80737C5.54322 9.11499 5.77393 9.36279 6.09863 9.36279ZM6.09863 11.8579H14.9084C15.2246 11.8579 15.4553 11.6187 15.4553 11.311C15.4553 10.9949 15.2246 10.7556 14.9084 10.7556H6.09863C5.77393 10.7556 5.54322 10.9949 5.54322 11.311C5.54322 11.6187 5.77393 11.8579 6.09863 11.8579ZM6.09863 14.3445H11.6357C11.9519 14.3445 12.1912 14.1137 12.1912 13.8061C12.1912 13.49 11.9519 13.2422 11.6357 13.2422H6.09863C5.77393 13.2422 5.54322 13.49 5.54322 13.8061C5.54322 14.1137 5.77393 14.3445 6.09863 14.3445Z"
    />
  </Box>
)
export default Logo
