import { SvgIconProps } from '@mui/material'
import * as Icons from '@mui/icons-material'
// Define the props type, including the dynamic icon name and any MUI icon props
interface IconRendererProps extends SvgIconProps {
  iconName: string
}

const IconRenderer: React.FC<IconRendererProps> = ({ iconName, ...props }) => {
  //@ts-ignore
  const IconComponent = Icons[iconName]

  return <IconComponent {...props} />
}

export default IconRenderer
