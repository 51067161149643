import { useApi } from '../../../providers/ApiProvider'

const API_BASE_URL = '/api/individual/'
const useIndividualJourney = () => {
  const api = useApi()

  const getJourney = async (patient_id: string) => {
    const data = await api.get(`${API_BASE_URL}journey/${patient_id}`)
    return data.data
  }

  return {
    getJourney,
  }
}

export default useIndividualJourney
