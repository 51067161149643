import Draggable from 'react-draggable'
import Note from './Note'
import { IconButton, Paper } from '@mui/material'
import { useState } from 'react'
import GraphicEq from '@mui/icons-material/GraphicEq'

const VoiceNote = () => {
  const [open, setOpen] = useState(false)
  const [position, setPosition] = useState({
    x: parseInt(localStorage.getItem('notes-pos-x') || '0'),
    y: parseInt(localStorage.getItem('notes-pos-y') || '0'),
  })

  if (!open) {
    return (
      <IconButton
        onClick={() => {
          setOpen(true)
        }}
      >
        <GraphicEq color="secondary" />
      </IconButton>
    )
  }

  return (
    <Draggable
      defaultPosition={position}
      bounds={'body'}
      onStop={(_, data) => {
        setPosition({ x: data.x, y: data.y })
        localStorage.setItem('notes-pos-x', data.x.toString())
        localStorage.setItem('notes-pos-y', data.y.toString())
      }}
    >
      <Paper
        sx={({ shadows }) => ({
          width: 'min-content',
          p: 2,
          boxShadow: shadows[10],
          position: 'absolute',
          zIndex: 1000,
        })}
      >
        <Note
          open={open}
          close={() => {
            setOpen(false)
          }}
        />
      </Paper>
    </Draggable>
  )
}

export default VoiceNote
