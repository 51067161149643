import React from 'react'
import { NodeProps, Position, Handle } from '@xyflow/react'
import '@xyflow/react/dist/style.css'
import { Box, Paper } from '@mui/material'
import { CustomNodeProps } from '../typing'
import { DropzoneArea } from 'mui-file-dropzone'
const handleStyle: React.CSSProperties = {
  width: '12px',
  height: '12px',
  background: '#555',
  border: '2px solid white',
}
// Custom Node Component
const FileUploadNode: React.FC<NodeProps<CustomNodeProps>> = ({ data }) => {
  return (
    <Paper
      variant="outlined"
      sx={{
        width: '240px',
        p: 2,
        position: 'relative',
        bgcolor: 'background.paper',
        borderRadius: '20px',
        borderStyle: 'dotted',
        borderWidth: 3,
        ':hover': {
          bgcolor: 'primary.light',
          color: 'primary.dark',
        },
      }}
    >
      <Handle type="target" position={Position.Left} style={handleStyle} />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Box
          sx={{
            width: 40,
            height: 40,
            borderRadius: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: data.color,
          }}
        >
          {data.icon}
        </Box>
        <Box>
          <Box
            sx={({ typography }) => ({
              '&.MuiBox-root > .MuiBox-root > .MuiBox-root:nth-of-type(2)': {
                '& .MuiBox-root': {
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.5,
                  paddingRight: 1,
                  paddingLeft: 1,
                  '& svg': {
                    width: 18,
                    height: 18,
                  },
                  '& p': {
                    ...typography.caption,
                  },
                  '& button': {
                    mr: 10,
                  },
                },
              },
              '& .mini': {
                ...typography.body3,
                my: 0.5,
              },
              '& .root': {
                height: 100,
                minHeight: 100,
              },
              '& .icon': {
                width: 18,
                height: 18,
              },
              '& .mini-container': {
                height: 'min-content',
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
                paddingRight: 1,
                paddingLeft: 1,
              },
            })}
          >
            <DropzoneArea
              acceptedFiles={['text/csv']}
              fileObjects={[]}
              showAlerts={false}
              inputProps={{}}
              showPreviews={false}
              showFileNames={true}
              filesLimit={1}
              classes={{
                text: 'mini',
                root: 'root',
                icon: 'icon',
                textContainer: 'mini-container',
              }}
            />
          </Box>
        </Box>
      </Box>
      <Handle type="source" position={Position.Right} style={handleStyle} />
    </Paper>
  )
}

export default FileUploadNode
