import React, { useCallback, useEffect, useState } from 'react'
import {
  ReactFlow,
  MiniMap,
  Controls,
  useNodesState,
  useEdgesState,
  ReactFlowProvider,
  addEdge,
  Background,
} from '@xyflow/react'
import '@xyflow/react/dist/style.css'
import { ICustomNode, CustomEdge } from '../../CustomNode/typing'
import CustomNode from '../../CustomNode'
import { delay, initialNodes } from './constant'
import { Box } from '@mui/material'
import { Protect } from '@clerk/clerk-react'

const nodeTypes = {
  custom: CustomNode,
}

const AnimatedFlowView: React.FC = () => {
  const [nodes, setNodes, onNodesChange] =
    useNodesState<ICustomNode>(initialNodes)
  const [edges, setEdges, onEdgesChange] = useEdgesState<CustomEdge>([])
  const [currentStep, setCurrentStep] = useState<number>(0)

  const showNextBlock = useCallback(() => {
    if (currentStep < initialNodes.length) {
      //   Show current node
      setNodes((nds) =>
        nds.map((node, index) => {
          if (index === currentStep) {
            return {
              ...node,
              data: { ...node.data, visible: true },
            }
          }
          return node
        })
      )

      // Animate edge if not the last node
      if (currentStep < initialNodes.length) {
        setEdges((eds) =>
          addEdge(
            {
              id: `e${currentStep + 1}-${currentStep + 2}`,
              source: `n${currentStep + 1}`,
              target: `n${currentStep + 2}`,
              animated: true,
              style: { opacity: 1 },
            },
            eds.map((e) => {
              e.animated = false
              return { ...e }
            })
          )
        )
      }
      setCurrentStep((prev) => prev + 1)
    }
  }, [currentStep, setNodes, setEdges])

  useEffect(() => {
    const timer = setTimeout(showNextBlock, delay)
    return () => clearTimeout(timer)
  }, [currentStep, showNextBlock])

  return (
    <Protect role="org:global_admin">
      <Box sx={{ width: '100%', height: 'calc(100vh - 102px)' }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          nodeTypes={nodeTypes}
          fitView
        >
          <Controls />
          <MiniMap />
          <Background />
        </ReactFlow>
      </Box>
    </Protect>
  )
}

const AnimatedFlow: React.FC = () => {
  return (
    <ReactFlowProvider>
      <AnimatedFlowView />
    </ReactFlowProvider>
  )
}

export default AnimatedFlow
