import { ClerkProvider } from '@clerk/clerk-react'
import ThemeToggleProvider from './providers/ThemeToggle'
import Root from './Root'
import { dark } from '@clerk/themes'
import { useTheme } from '@mui/material/styles'
import { ApiProvider } from './providers/ApiProvider'
import { ToastProvider } from './providers/Toast'

const AppWithTheme = () => {
  const { palette, typography } = useTheme()
  return (
    <ClerkProvider
      appearance={{
        baseTheme: palette.mode === 'dark' ? dark : undefined,
        variables: {
          fontFamily: typography.fontFamily,
          colorText: palette.text.primary,
          colorTextSecondary: palette.text.secondary,
          colorDanger: palette.error.main,
          colorSuccess: palette.success.main,
          colorWarning: palette.warning.main,
          colorPrimary: palette.text.primary,
        },
      }}
      publishableKey={process.env.REACT_APP_CLERK_PUBLISHABLE_KEY || ''}
    >
      <ApiProvider>
        <Root />
      </ApiProvider>
    </ClerkProvider>
  )
}

function App() {
  return (
    <ThemeToggleProvider>
      <ToastProvider>
        <AppWithTheme />
      </ToastProvider>
    </ThemeToggleProvider>
  )
}

export default App
