import { useEffect, useState } from 'react'
// import useDebouncedTyping from './useDebouncedTyping'
import { PartialBlock } from '@blocknote/core'

const useAutoSave = (
  isEdit: boolean,
  editor: any,
  save: any,
  label?: any,
  keywords?: any,
  attendees?: any
) => {
  const [blocks, setBlocks] = useState<PartialBlock[]>(editor.document)

  // Auto save when moves out of page
  useEffect(() => {
    return () => {
      if (isEdit) {
        save(true)
      }
    }
  }, [isEdit])

  useEffect(() => {
    const handler = setTimeout(() => {
      if (
        isEdit &&
        ((editor && editor.document) || label || keywords || attendees)
      ) {
        console.log('Auto Saving')
        save(true)
      }
    }, 3000) // Save after 3 second of inactivity

    return () => {
      clearTimeout(handler)
    }
  }, [isEdit, blocks, label, keywords, attendees])

  return [setBlocks] as const
}

//   useEffect(() => {
//     // Cleanup function to be called on unmount
//     return () => {
//       console.log('Saving editor values on unmount:', editor.document)
//       if (isEdit) {
//         setBlocks(editor.document)
//         save(true)
//       }
//     }
//   }, [])

//   const [blocks, setBlocks] = useState<PartialBlock[]>(editor.document)
//   const triggerEvent = (value: any) => {
//     const editorString = JSON.stringify(blocks)
//     if (value && editorString !== value) {
//       console.log('Saving editor values:', editorString)
//       setBlocks(editor.document)
//       save(true)
//     }
//   }
//   const [inputValue, setInputValue] = useDebouncedTyping(triggerEvent, 3000)

export default useAutoSave
