import { GridColDef } from '@mui/x-data-grid'
import CalendarTodayOutlined from '@mui/icons-material/CalendarTodayOutlined'
import AccessTimeOutlined from '@mui/icons-material/AccessTimeOutlined'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import MuiLink from '@mui/material/Link'
import { Link } from 'react-router-dom'
import OpenInNew from '@mui/icons-material/OpenInNew'
import { formatUSPhoneNumber, getColorForType } from './util'

export const cols: GridColDef[] = [
  {
    field: 'assistant_id',
    headerName: 'ASSISTANT ID',
    cellClassName: 'small-cell',
    minWidth: 250,
  },
  {
    field: 'start_date',
    headerName: 'CALL DATE',
    minWidth: 100,
    cellClassName: 'small-cell',
    renderCell: (e) => (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
        <CalendarTodayOutlined
          sx={{ color: 'text.secondary', fontSize: '14px' }}
        />
        {e.value}
      </Box>
    ),
  },
  {
    field: 'start_time',
    headerName: 'START TIME',
    minWidth: 100,
    cellClassName: 'small-cell',
    renderCell: (e) => (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
        <AccessTimeOutlined
          sx={{ color: 'text.secondary', fontSize: '14px' }}
        />
        {e.value}
      </Box>
    ),
  },
  {
    field: 'end_time',
    headerName: 'END TIME',
    minWidth: 100,
    cellClassName: 'small-cell',
    renderCell: (e) => (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
        <AccessTimeOutlined
          sx={{ color: 'text.secondary', fontSize: '14px' }}
        />
        {e.value}
      </Box>
    ),
  },
  {
    field: 'call_id',
    headerName: 'CALL ID',
    cellClassName: 'small-cell',
    minWidth: 250,
  },
  {
    field: 'duration',
    headerName: 'DURATION',
    minWidth: 100,
    cellClassName: 'small-cell',
    renderCell: (e) => (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
        <AccessTimeOutlined
          sx={{ color: 'text.secondary', fontSize: '14px' }}
        />
        {e.value}
      </Box>
    ),
  },
  {
    field: 'type',
    headerName: 'TYPE',
    cellClassName: 'small-cell',
    minWidth: 150,
    renderCell: (e) => (
      <Chip
        label={e.value || '--'}
        size={'small'}
        sx={(theme) => ({
          background: getColorForType(e.value || '--', theme.palette.mode),
        })}
      />
    ),
  },
  {
    field: 'cost',
    headerName: 'COST',
    cellClassName: 'small-cell',
    minWidth: 30,
    valueFormatter: (e) => {
      const val = parseFloat(e).toFixed(2)
      return `$ ${val}`
    },
  },
  {
    field: 'end_reason',
    headerName: 'ENDED REASON',
    cellClassName: 'small-cell',
    minWidth: 130,
    renderCell: (e) => (
      <Chip
        label={e.value || '--'}
        size={'small'}
        sx={(theme) => ({
          background: getColorForType(e.value || '--', theme.palette.mode),
        })}
      />
    ),
  },
  {
    field: 'callee_number',
    headerName: 'CUSTOMER NUMBER',
    cellClassName: 'small-cell',
    minWidth: 140,
    valueFormatter: formatUSPhoneNumber,
  },
  {
    field: 'url',
    headerName: 'Link',
    cellClassName: 'small-cell',
    minWidth: 120,
    renderCell: (e) => (
      <MuiLink
        component={Link}
        color="text.secondary"
        target="_blank"
        to={e.value}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        Call recording{' '}
        <OpenInNew sx={{ color: 'text.secondary', fontSize: '14px', ml: 1 }} />
      </MuiLink>
    ),
  },
  {
    field: 'kpis',
    headerName: 'KEY POINTS',
    cellClassName: 'small-cell',
    minWidth: 850,
  },
]
