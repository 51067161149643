import * as jose from 'jose'

const enc = new TextEncoder()
const secret = enc.encode(process.env.REACT_APP_EXPLO_KEY)

export const generateJwt = (customer: string, dashboardId: string) =>
  new jose.EncryptJWT({
    cst: customer,
    dsh: dashboardId,
  })
    .setProtectedHeader({
      alg: 'dir',
      enc: 'A256GCM',
      kid: process.env.REACT_APP_EXPLO_KID,
    })
    .setIssuedAt()
    .setExpirationTime('2h')
    .encrypt(secret)

export const currencyFormat = (num: any, type?: string) => {
  num = Number(num)
  return type === 'USD'
    ? '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    : num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
