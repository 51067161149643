import { FC, PropsWithChildren } from 'react'
import styles from './animated.module.css' // Adjust the path as necessary
import Box from '@mui/material/Box'

const DotsBackground: FC<PropsWithChildren> = ({ children }) => {
  const dots = Array.from({ length: 75 }, (_, i) => i + 1)

  return (
    <Box
      className={styles.bg}
      sx={({ palette }) => ({
        background: palette.mode === 'dark' ? '#111' : '#fff',
      })}
    >
      {dots.map((dot) => (
        <Box
          key={dot}
          className={`${styles.dotWrapper} ${styles[`dotWrapper-${dot}`]}`}
        >
          <Box className={`${styles.dot} ${styles[`dot-${dot}`]}`}></Box>
        </Box>
      ))}
      <Box sx={{ zIndex: 1 }}>{children}</Box>
    </Box>
  )
}

export default DotsBackground
