import { TypographyOptions } from '@mui/material/styles/createTypography'
import breakpoints from './beakpoints'

const baseTypography = {
  // display
  h1: {
    fontSize: '64px',
    lineHeight: '70px',
  },
  h2: {
    fontSize: '44px',
    lineHeight: '50px',
  },
  h3: {
    fontSize: '36px',
    lineHeight: '42px',
    fontWeight: 600,
  },
  // heading
  h4: {
    fontSize: '32px',
    lineHeight: '38px',
    fontWeight: 600,
  },
  h5: {
    fontSize: '24px',
    lineHeight: '28px',
  },
  h6: {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 700,
  },
  // title
  body1: {
    fontSize: '22px',
    lineHeight: '26px',
  },
  body2: {
    fontSize: '18px',
    lineHeight: '22px',
  },
  body3: {
    fontSize: '16px',
    lineHeight: '20px',
  },
  // label / body
  subtitle1: {
    fontSize: '16px',
    lineHeight: '20px',
  },
  subtitle2: {
    fontSize: '14px',
    lineHeight: '16px',
  },
  subtitle3: {
    fontSize: '12px',
    lineHeight: '16px',
  },
  overline: {},
  button: {
    fontSize: '18px',
  },
}

const typography: TypographyOptions = {
  fontFamily: ['Lato', 'sans-serif'].join(','),
  h1: {
    ...baseTypography.h1,
    [`@media (max-width: ${breakpoints.values?.md || 720}px)`]: {
      ...baseTypography.h2,
    },
  },
  h2: {
    ...baseTypography.h2,
    [`@media (max-width: ${breakpoints.values?.md || 720}px)`]: {
      ...baseTypography.h3,
    },
  },
  h3: {
    ...baseTypography.h3,
    [`@media (max-width: ${breakpoints.values?.md || 720}px)`]: {
      ...baseTypography.h4,
    },
  },
  h4: {
    ...baseTypography.h4,
    [`@media (max-width: ${breakpoints.values?.md || 720}px)`]: {
      ...baseTypography.h5,
    },
  },
  h5: {
    ...baseTypography.h5,
    [`@media (max-width: ${breakpoints.values?.md || 720}px)`]: {
      ...baseTypography.h6,
    },
  },
  h6: {
    ...baseTypography.h6,
    [`@media (max-width: ${breakpoints.values?.md || 720}px)`]: {
      ...baseTypography.body1,
    },
  },
  body1: {
    ...baseTypography.body1,
    [`@media (max-width: ${breakpoints.values?.md || 720}px)`]: {
      ...baseTypography.body2,
    },
  },
  body2: {
    ...baseTypography.body2,
    [`@media (max-width: ${breakpoints.values?.md || 720}px)`]: {
      ...baseTypography.body3,
    },
  },
  body3: {
    ...baseTypography.body3,
    [`@media (max-width: ${breakpoints.values?.md || 720}px)`]: {
      ...baseTypography.subtitle1,
    },
  },
  subtitle1: {
    ...baseTypography.subtitle1,
    [`@media (max-width: ${breakpoints.values?.md || 720}px)`]: {
      ...baseTypography.subtitle2,
    },
  },
  subtitle2: {
    ...baseTypography.subtitle2,
  },
  subtitle3: {
    ...baseTypography.subtitle3,
  },
}
export default typography
