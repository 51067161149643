import Navbar from '../Navbar'
import { FC, PropsWithChildren, useContext } from 'react'
import Box from '@mui/material/Box'
import { Drawer, Toolbar } from '@mui/material'
import { DrawerContext } from '../App'
import { useNavigate } from 'react-router-dom'

const LandingLayout: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate()
  const [showDrawer, setShowDrawer] = useContext(DrawerContext)

  const closeDrawer = () => {
    navigate(-1)
    setShowDrawer(false)
  }

  return (
    <Box sx={{ height: '100vh', display: 'flex' }}>
      <Navbar />
      <Box
        component={'main'}
        sx={{ height: 'calc(100vh - 64px)', mt: 8, width: '100%' }}
      >
        <Box sx={{ p: 2 }}>{children}</Box>
      </Box>

      <Drawer
        anchor="bottom"
        open={showDrawer}
        onClose={() => closeDrawer()}
        sx={{
          height: '90%',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            height: '90%',
            boxSizing: 'border-box',
          },
        }}
      >
        <Box sx={{ p: 2 }}>{children}</Box>
      </Drawer>
    </Box>
  )
}
export default LandingLayout
