import React, { FC, useEffect, useState } from 'react'
import {
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridRowModel,
  GridSortModel,
  GridValidRowModel,
} from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import useTablesApi from './api'
import Typography from '@mui/material/Typography'
import Loader from '../Loader'
import { Protect, useAuth } from '@clerk/clerk-react'
import { CustomColumnOptions, ExportOptions } from './db.typing'
import { formatHeaderName } from './util'
import CustomToolbar from './CustomToolbar'
import { defaultPageSize } from './const'
import useWs, { ChangeEventData } from './ws'
import Grid from '../common/Grid'
import GridContainer from '../common/GridContainer'
import debounce from '../../util/debounce'

interface TableEditorViewProps {
  tableName: string
  getRowId?: (e: any) => any
  readonly?: boolean
  style?: {
    height?: string
    width?: string
  }
  readonlyFields?: string[]
  customColumnOptions?: Record<string, CustomColumnOptions>
  defaultPageSize?: number
  hideHeader?: boolean
  enableCellColor?: boolean
  onEditComplete?: () => void
  exportOptions?: ExportOptions
  columOverrides?: GridColDef[]
  columnSortPreference?: string[]
  hideFields?: string[]
  defaultSortOption?: GridSortModel
  liveSync?: boolean
}

const TableEditorView: React.FC<TableEditorViewProps> = ({
  tableName,
  exportOptions = {},
  customColumnOptions = {},
  style = {},
  getRowId,
  readonly,
  hideHeader,
  enableCellColor = false,
  onEditComplete,
  readonlyFields = [],
  columOverrides,
  defaultSortOption = [],
  columnSortPreference = [],
  hideFields = [],
  // liveSync = true,
}) => {
  const [rows, setRows] = useState<GridValidRowModel[]>([])
  const [isExporting, setIsExporting] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const { has } = useAuth()
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: defaultPageSize,
  })
  const [sortModel, setSortModel] = useState<GridSortModel>(defaultSortOption)
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  })

  const editPermission =
    has?.({ permission: 'org:feature:db_table_edit' }) || false

  const {
    countRows,
    exportAllData,
    fetchData,
    fetchTableStructure,
    updateCell,
  } = useTablesApi()

  const tableDef = useQuery({
    queryKey: ['table-def', tableName],
    queryFn: () =>
      fetchTableStructure(
        tableName,
        columOverrides,
        hideFields,
        columnSortPreference
      ),
    enabled: Boolean(tableName),
  })

  const defaultExportOptions: ExportOptions = {
    fileName: `${tableName}-export`,
    delimiter: ',',
    includeHeaders: true,
    dateFormat: 'yyyy-MM-dd HH:mm:ss',
    customFormatters: {},
    excludeColumns: [],
  }

  const mergedExportOptions = { ...defaultExportOptions, ...exportOptions }

  const tableData = useQuery({
    queryKey: [
      'table-data',
      tableName,
      filterModel,
      sortModel,
      paginationModel,
    ],
    queryFn: () =>
      fetchData(tableName, filterModel, sortModel, paginationModel),
    enabled: Boolean(tableName),
  })

  const tableCount = useQuery({
    queryKey: ['table-data-count', tableName],
    queryFn: () => countRows(tableName),
    enabled: Boolean(tableName),
  })

  const handleRealtimeUpdateWs = (payload: ChangeEventData) => {
    console.log('Change is here :: ', payload)
    switch (payload.type) {
      case 'INSERT':
        setRows((prevRows) => [payload.record, ...prevRows])
        break
      case 'UPDATE':
        setRows((prevRows) =>
          prevRows.map((row) =>
            row.id === payload.record.id ? payload.record : row
          )
        )
        break
      case 'DELETE':
        setRows((prevRows) =>
          prevRows.filter((row) => row.id !== payload.old_record.id)
        )
        break
    }
  }

  useWs(tableName, handleRealtimeUpdateWs)

  const handleCellEdit = async (params: {
    id: number
    field: string
    value: any
  }): Promise<void> => {
    try {
      await updateCell(tableName, params.id, params.field, params.value)
      onEditComplete?.()
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown error'
      setError(errorMessage)
      console.error('Error updating cell:', error)
      tableData.refetch()
      // fetchData();
    }
  }

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel)
  }

  const handleFilterModelChange = (newModel: GridFilterModel) => {
    debounce(() => setFilterModel(newModel), 3000)()
  }

  const handlePaginationModelChange = (newModel: GridPaginationModel) => {
    setPaginationModel(newModel)
  }

  useEffect(() => {
    if (tableName) {
      setRows(tableData.data?.rows || [])
    }
  }, [tableName, tableData.data?.rows])

  if (tableData.isLoading || tableDef.isLoading) {
    return <Loader />
  }

  if (error) {
    return <div>Error: {error}</div>
  }

  return (
    <>
      {!hideHeader && (
        <Typography variant="h6" sx={{ pb: 1 }}>
          {formatHeaderName(tableName)}
        </Typography>
      )}
      <GridContainer height={style.height} width={style.width}>
        <Grid
          disableRowSelectionOnClick={true}
          checkboxSelection
          disableColumnFilter={false}
          rowHeight={40}
          rows={rows}
          getRowId={getRowId}
          columns={tableDef.data?.columns || []}
          loading={tableData.isLoading || tableDef.isLoading}
          pageSizeOptions={[25, 50, 100]}
          isCellEditable={(e) => {
            if (readonly) {
              return false
            }
            if (readonlyFields.includes(e.field)) {
              return false
            }
            return editPermission
          }}
          getCellClassName={(params: any) => {
            if (
              params.field === '__check__' ||
              params.field === 'Service Name' ||
              params.field === 'Care Gap Type' ||
              params.field === 'Care Gaps Closed / Numerator' ||
              params.field === 'Care Gaps Open / Denominator' ||
              params.value == null ||
              !enableCellColor
            ) {
              return ''
            }
            if (params.field === '% Of Care Gaps Closed') {
              return params?.value <= 50 ? 'medium' : 'low'
            }
            return params?.value <= 10
              ? 'low'
              : params?.value <= 90
                ? 'medium'
                : 'high'
          }}
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          initialState={{
            pagination: { paginationModel: { pageSize: defaultPageSize } },
          }}
          rowCount={tableCount.data?.count || 0}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          filterMode="server"
          filterModel={filterModel}
          columnVisibilityModel={{
            id: false,
            created_at: false,
          }}
          onFilterModelChange={handleFilterModelChange}
          processRowUpdate={(newRow: GridRowModel, oldRow: GridRowModel) => {
            const changedField = Object.keys(newRow).find(
              (key) => newRow[key] !== oldRow[key]
            )
            if (changedField) {
              handleCellEdit({
                id: newRow.id,
                field: changedField,
                value: newRow[changedField],
              })
            }
            return newRow
          }}
          onProcessRowUpdateError={(error: Error) => {
            console.error('Error updating row:', error)
            setError(error.message)
          }}
          slots={{
            toolbar: () => (
              <CustomToolbar
                exportOptions={mergedExportOptions}
                isExporting={isExporting}
                onExport={() => {
                  setIsExporting(true)
                  exportAllData(
                    tableName,
                    filterModel,
                    sortModel,
                    paginationModel,
                    mergedExportOptions,
                    customColumnOptions,
                    tableDef.data?.columns || [],
                    tableCount.data?.count
                  ).finally(() => {
                    setIsExporting(false)
                  })
                }}
              />
            ),
          }}
        />
      </GridContainer>
    </>
  )
}

const TableEditor: FC<TableEditorViewProps> = ({ tableName, ...rest }) => {
  return (
    <Protect>
      <TableEditorView tableName={tableName} {...rest} />
    </Protect>
  )
}

export default TableEditor
