import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Logo from '../icons/Logo'
import { useNavigate } from 'react-router'
import { FC, PropsWithChildren } from 'react'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import {
  OrganizationSwitcher,
  Protect,
  UserButton,
  useUser,
} from '@clerk/clerk-react'
import Breadcrumbs from './Breadcrumb'
import VoiceNote from '../VoiceNote'

const drawerWidth = 200
export const ResponsiveDrawer: FC<PropsWithChildren> = ({ children }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [isClosing, setIsClosing] = React.useState(false)
  const { breakpoints } = useTheme()
  const isMobile = useMediaQuery(breakpoints.down('md'))
  const { user } = useUser()
  const ref = React.useRef<HTMLElement>(null)
  const navigate = useNavigate()

  const handleDrawerClose = () => {
    setIsClosing(true)
    setMobileOpen(false)
  }

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false)
  }

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen)
    }
  }

  return (
    <>
      <AppBar
        position="fixed"
        sx={({ palette }) => ({
          // width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          boxShadow: '1px 1px #E0E3E7',
          background: palette.mode === 'dark' ? '#1f1f1f' : '#fff',
        })}
        ref={ref}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box display={'flex'}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 0.5, display: { sm: 'none' } }}
            >
              <MenuIcon fontSize="small" />
            </IconButton>
            <Box sx={{ display: 'flex' }}>
              <Logo
                sx={{
                  cursor: 'pointer',
                  height: isMobile ? 20 : 30,
                  width: isMobile ? 20 : 30,
                  mr: 1.5,
                }}
                onClick={() => navigate('/')}
              />
              <Breadcrumbs />
            </Box>
          </Box>
          <Box display={'flex'} gap={2}>
            <Protect permission="org:feature:voice_notes">
              <VoiceNote />
            </Protect>
            {user?.primaryEmailAddress?.emailAddress?.endsWith(
              '@helpcare.ai'
            ) && (
              <Protect role="org:global_admin">
                <OrganizationSwitcher hidePersonal hideSlug />
              </Protect>
            )}
            <UserButton afterSignOutUrl="/" />
          </Box>
        </Toolbar>
      </AppBar>
      <Box sx={{ display: 'flex' }}>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {isMobile ? (
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onTransitionEnd={handleDrawerTransitionEnd}
              onClose={handleDrawerClose}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', sm: 'block', md: 'none' },
                '& .MuiDrawer-paper': {
                  pt: 2,
                  boxSizing: 'border-box',
                  width: drawerWidth,
                  height: '100vh',
                },
              }}
            >
              {children}
            </Drawer>
          ) : (
            <Drawer
              variant="permanent"
              sx={({ palette }) => ({
                zIndex: 1,
                display: { xs: 'none', sm: 'none', md: 'block' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: drawerWidth,
                  height: `calc(100vh - ${ref.current?.clientHeight}px)`,
                  top: ref.current?.clientHeight || 64,
                  background: palette.mode === 'light' && '#F1F4F8',
                  zIndex: 1,
                },
              })}
              open
            >
              {children}
            </Drawer>
          )}
        </Box>
      </Box>
    </>
  )
}
