import { useApi } from '../../providers/ApiProvider'

import {
  BlockSchema,
  InlineContentSchema,
  PartialBlock,
  StyleSchema,
} from '@blocknote/core'

const API_BASE = '/api/actions/'

export interface RootResponse {
  id: string
  created_at: string
  action_for: string[]
  blocks: PartialBlock<
    NoInfer<BlockSchema>,
    NoInfer<InlineContentSchema>,
    NoInfer<StyleSchema>
  >[]
  action_time: string
  meeting_id: string
}

export interface Block {
  type: string
  content: string
}

const useActionsApi = () => {
  const api = useApi()

  const getMyActionItems = async () => {
    const data = await api.get<RootResponse[]>(`${API_BASE}my`)
    return data.data
  }

  const saveActionItem = async (id: string, blocks: any[]) => {
    const data = await api.put<RootResponse>(`${API_BASE}${id}`, { blocks })
    return data.data
  }

  return {
    getMyActionItems,
    saveActionItem,
  }
}

export default useActionsApi
