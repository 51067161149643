import Paper from '@mui/material/Paper'
import Markdown from 'react-markdown'

const markdown = `**Overview:**
Angelica from Helpcare AI contacts Jessica Arvaz to schedule a cervical cancer PAP screening. Angelica verifies Jessica’s identity and explains the importance of the screening, addressing Jessica’s concerns about necessity, frequency, and potential discomfort. Jessica requests a female doctor for the appointment. After confirming personal information, Angelica books Jessica’s PAP screening for Tuesday, August 16th at 11 a.m., promising to send a reminder and calendar invite. Jessica is reminded to bring her insurance and ID and confirms she has the clinic’s address.

**Action Items:**
1. **Appointment Confirmation**: Jessica’s PAP screening is scheduled for Tuesday, August 16th at 11 a.m. with a female doctor.
2. **Documentation**: Jessica should bring her insurance card and ID to the appointment.
`
const Transcript = () => {
  return (
    <Paper
      variant="elevation"
      elevation={4}
      sx={({ typography }) => ({
        px: 2,
        py: '2px',
        width: 1000,
        m: 0,
        '& p, ol': {
          ...typography.subtitle3,
        },
      })}
    >
      <Markdown>{markdown}</Markdown>
    </Paper>
  )
}

export default Transcript
