import { XYPosition } from '@xyflow/react'
import { useApi } from '../../providers/ApiProvider'
import { BaseCustomNode, NodeTypes } from './typing'

const API_BASE = '/api/workflow/'

interface WorkflowGenerationRequest {
  name?: string
}

interface WorkFlowGenerationResponse extends WorkflowGenerationRequest {
  id: string
}

export interface WorkflowDetails extends WorkFlowGenerationResponse {
  edges: any[]
  nodes: any[]
  created_at: string
}

export interface WorkFlowUpdateRequest {
  edges: any[]
  nodes: any[]
}

export interface NodeCreationRequest<T> {
  data: T
  position: XYPosition
  workflow_id: string
  type: NodeTypes
}

export interface NodeUpdateRequest<T> {
  data: T
  position?: XYPosition
  workflow_id: string
}

export interface EdgeCreationRequest {
  source: string
  target: string
  workflow_id: string
}

const useWorkflowApi = () => {
  const api = useApi()

  const createWorkflow = async (request: WorkflowGenerationRequest) => {
    const data = await api.post<WorkFlowGenerationResponse>(
      `${API_BASE}`,
      request
    )
    return data.data
  }

  const getAllWorkflow = async () => {
    const data = await api.get<any>(`${API_BASE}`)
    return data.data
  }

  const getWorkflow = async (workflow_id: string) => {
    const data = await api.get<WorkflowDetails>(`${API_BASE}${workflow_id}`)
    return data.data
  }

  const updateWorkFlow = async (
    workflow_id: string,
    request: WorkFlowUpdateRequest
  ) => {
    const { nodes, edges } = request
    const updateNodes = nodes.map((e) => {
      const node = { ...e }
      delete node.data.isEditing
      return node
    })
    const updatedEdges = edges.map((e) => {
      const edge = { ...e }
      delete edge.id
      return {
        ...edge,
        workflow_id: workflow_id,
      }
    })
    const data = await api.put<WorkflowDetails>(`${API_BASE}${workflow_id}`, {
      nodes: updateNodes,
      edges: updatedEdges,
    })
    return data.data
  }

  const addNode = async <T>(request: NodeCreationRequest<T>) => {
    const { workflow_id, ...rest } = request
    const data = await api.post(`${API_BASE}${workflow_id}/node`, rest)
    return data.data
  }

  const updateNode = async <T extends BaseCustomNode>(
    nodeId: string,
    request: NodeUpdateRequest<T>
  ) => {
    const { workflow_id, data, position } = request
    const apiReq = { ...data }
    delete apiReq.isEditing
    const req: Omit<NodeUpdateRequest<T>, 'workflow_id'> = {
      data: apiReq,
      position,
    }
    const res = await api.put(`${API_BASE}${workflow_id}/node/${nodeId}`, req)
    return res.data
  }

  const deleteNode = async (workflow_id: string, nodeId: string) => {
    const data = await api.delete(`${API_BASE}${workflow_id}/node/${nodeId}`)
    return data.data
  }

  const addEdge = async (request: EdgeCreationRequest) => {
    const { workflow_id, ...rest } = request
    const data = await api.post(`${API_BASE}${workflow_id}/edge`, rest)
    return data.data
  }

  const updateEdge = async (edgeId: string, request: EdgeCreationRequest) => {
    const { workflow_id, ...rest } = request
    const data = await api.put(`${API_BASE}${workflow_id}/edge/${edgeId}`, rest)
    return data.data
  }

  const deleteEdge = async (workflow_id: string, edgeId: string) => {
    const data = await api.delete(`${API_BASE}${workflow_id}/edge/${edgeId}`)
    return data.data
  }

  return {
    workflow: {
      create: createWorkflow,
      get: getWorkflow,
      getAll: getAllWorkflow,
      update: updateWorkFlow,
    },
    node: {
      add: addNode,
      update: updateNode,
      delete: deleteNode,
    },
    edge: {
      add: addEdge,
      update: updateEdge,
      delete: deleteEdge,
    },
  }
}

export default useWorkflowApi
