import React, { FC } from 'react'
import { NodeAction } from './typing'
import { Box, Fade, Paper, Popper } from '@mui/material'

const Action: FC<NodeAction & { location: 'top' | 'bottom' }> = ({
  tooltip,
  icon,
  secondaryIcon,
  location,
}) => {
  const [open, setOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen((previousOpen) => !previousOpen)
  }

  return (
    <>
      {tooltip && (
        <Popper
          open={open}
          anchorEl={anchorEl}
          transition
          placement={location}
          sx={{
            [`margin${location === 'bottom' ? 'Top' : 'Bottom'}`]:
              '16px !important',
          }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                variant="elevation"
                sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}
              >
                {tooltip}
              </Paper>
            </Fade>
          )}
        </Popper>
      )}
      {location === 'top' ? (
        <>
          {secondaryIcon && (
            <Box onClick={(e) => handleClick(e)}>{secondaryIcon}</Box>
          )}
          {icon}
        </>
      ) : (
        <>
          {icon}
          {secondaryIcon && (
            <Box onClick={(e) => handleClick(e)}>{secondaryIcon}</Box>
          )}
        </>
      )}
    </>
  )
}

export default Action
