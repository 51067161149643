import { ColumnTypeMap, ColumnWidthMap } from './db.typing'

export const defaultPageSize = 25

export const columnWidthMap: ColumnWidthMap = {
  'character varying': 200,
  integer: 130,
  boolean: 130,
  'timestamp with time zone': 200,
  date: 150,
  numeric: 130,
  text: 300,
}

export const columnTypeMap: ColumnTypeMap = {
  integer: 'number',
  numeric: 'number',
  boolean: 'boolean',
  'timestamp with time zone': 'dateTime',
  date: 'date',
  'character varying': 'string',
  text: 'string',
}
